import React from 'react'
import TasksStore from './TasksStore';
import DutyStore from './DutyStore';
import CalendarStore from './CalendarStore';
import MapStore from './MapStore';
import StaffStore from './StaffStore';
import TranslationsStore from './TranslationsStore';
import ContractsStore from './ContractsStore';
import RequestsStore from './RequestsStore';
import HomepageStore from './HomepageStore';
import AnnouncementsStore from './AnnouncementsStore';
import PartsStore from './PartsStore';
import AuditStore from './AuditStore';
import GoodsStore from './GoodsStore';
import NoticeStore from './NoticeStore';
import DefaultHeaderStore from './DefaultHeaderStore';
import SKUStore from './SKUStore';
import IssuesStore from './IssuesStore';
import CategoryAccessStore from './CategoryAccessStore';
import AccessoriesStore from './AccessoriesStore';
import CargoCompaniesStore from "./CargoCompaniesStore";
import ConsigneesStore from "./ConsigneesStore";
import BuyersStore from "./BuyersStore";
import UsersStore from "./UsersStore";
import ShippersStore from "./ShippersStore";
import FactoriesStore from "./FactoriesStore";
import PostsStore from "./PostsStore";
import DepartmentsStore from "./DepartmentsStore";
import PostfixesStore from "./PostfixesStore";
import CompetitorsStore from "./CompetitorsStore";
import DestinationPorts from "./DestinationPortsStore";
import QcFeedBackStore from './QcFeedbackStore';
import SampleTestStore from './SampleTestStore';
import NotificationStore from './NotificationStore';
import HeaderNotificationStore from './HeaderNotificationStore';
import InstructionsStore from './InstructionsStore'
import AgentsStore from './AgentsStore'
import UpdatesStore from './UpdatesStore';

export const storesContext = React.createContext({
    tasksStore: new TasksStore(),
    dutyStore: new DutyStore(),
    calendarStore: new CalendarStore(),
    mapStore: new MapStore(),
    staffStore: new StaffStore(),
    translationsStore: new TranslationsStore(),
    contractsStore: new ContractsStore(),
    accessoriesStore: new AccessoriesStore(),
    requestsStore: new RequestsStore(),
    announcementsStore: new AnnouncementsStore(),
    homepageStore: new HomepageStore(),
    partsStore: new PartsStore(),
    auditStore: new AuditStore(),
    goodsStore: new GoodsStore(),
    noticeStore: new NoticeStore(),
    defaultHeaderStore: new DefaultHeaderStore(),
    skuStore: new SKUStore(),
    issuesStore: new IssuesStore(),
    categoryAccessStore: new CategoryAccessStore(),
    CargoCompaniesStore: new CargoCompaniesStore(),
    ConsigneesStore: new ConsigneesStore(),
    BuyersStore: new BuyersStore(),
    UsersStore: new UsersStore(),
    ShippersStore: new ShippersStore(),
    FactoriesStore: new FactoriesStore(),
    PostsStore: new PostsStore(),
    DepartmentsStore: new DepartmentsStore(),
    PostfixesStore: new PostfixesStore(),
    CompetitorsStore: new CompetitorsStore(),
    DestinationPorts: new DestinationPorts(),
    QcFeedbackStore: new QcFeedBackStore(),
    SampleTestStore: new SampleTestStore(),
    NotificationStore: new NotificationStore(),
	HeaderNotificationStore: new HeaderNotificationStore(),
    InstructionsStore: new InstructionsStore(),
    AgentsStore: new AgentsStore(),
    UpdatesStore: new UpdatesStore(),
})
