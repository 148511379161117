import { makeAutoObservable } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
import i18next from 'i18next';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
const download = require('downloadjs')
const axios = api;

class TranslationsStore {
    isMainLoading = false
    isEditting = false
    btnLoading = {
      idx: null,
      type: null,
    }
    paginations = {
        page : 1,
        pageSize: 10,
        totalTranslations: null
    }
    filtersParams = {
      search: '',
      comment: {
        options:[],
        filtered: []
      },
      sortColumn: null,
      sortOrder: null
    }
    parsingModal = {
      open: false,
      progress: 0,
      data: [],
      countOfAdded: 0,
      countOfFailed: 0,
      countOfUpdated: 0,
      critError: null
    }
    translationsData = [];
    user = {}
    rowBeforeEdit = null;
    failedSound = new Audio('/assets/sounds/handBooks/failed.mp3')
    successSound = new Audio('/assets/sounds/handBooks/success.mp3')

    constructor() {
        this.debouncedGetTranslations = debounce(this.getTranslations, 500)
        makeAutoObservable(this, {}, { deep: true })
      }

      //Main model CRUD
      getTranslations= async () => {
        try{
          this.isMainLoading = true
          const params = {
            pageSize: this.paginations.pageSize,
            page: this.paginations.page,
            search: this.filtersParams.search.trim(),
            comment: this.filtersParams.comment.filtered,
            order: this.filtersParams.sortOrder,
            column: this.filtersParams.sortColumn,
          }
          let response = await axios.get(`/api/node/v1/translations`, { params })
          this.translationsData = response.data.data.rows
          this.user = response.data.data.user
          this.paginations.totalTranslations = response.data.data.count
          this.filtersParams.comment.options = response.data.data.commentOpt
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      createTranslation = async (body, index) => {
        try{
          this.isMainLoading = true
          this.btnLoading = {
            idx: index,
            type: 'save'
          }
          const {id, ...restBody} = body
          await axios.post(`/api/node/v1/translations`, restBody)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      updateTranslation= async (body, index) => {
        try{
          this.isMainLoading = true
          this.btnLoading = {
            idx: index,
            type: 'save'
          }
          await axios.put(`/api/node/v1/translations`, body)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      deleteTranslation = async (id) => {
        try{
          this.isMainLoading = true
          await axios.delete(`/api/node/v1/translations/${id}`)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      //Row operations

      editRow = async (index) => {
        this.rowBeforeEdit = {...this.translationsData[index]}
        this.translationsData[index].isEdit = true;
        this.isEditting = true
      }

      addRow = () => {
        let temp = [...this.translationsData];
        this.isEditting = true
        temp.unshift({
          id: 'New',
          key: 'w_',
          ru: '',
          en: '',
          cn: '',
          type: null,
          comment: '',
          isEdit: true
        })
        this.translationsData = temp
      }

      onFinishMainForm = async (values) => {
        let isUnique 
        if (this.rowBeforeEdit) {
          values.id = this.rowBeforeEdit.id
          isUnique = await this.getCheckKeyUnique(values.key , values.id )
          if(!isUnique) return
          values.type = values[`type_${this.rowBeforeEdit.id}`] ? 'category' : null
          await this.updateTranslation(values)
        } else {
          isUnique = await this.getCheckKeyUnique(values.key)
          if(!isUnique) return
          values.type = values.type_New ? 'category' : null
          
          await this.createTranslation(values)
        }
      }

      getCheckKeyUnique = async (key, id = null) => {
        let answer 
        try{
          const response = await axios.get(`/api/node/v1/translations/checkKeyUnique/${key}/${id}`)
          answer = response.data.data
          if(!answer) {
            this.openNotificationWithIcon('error', `${i18next.t('w_key_is_not_unique')}: ${key}`)
          }
        } catch (error) {
          console.error(error)
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally{
          return answer
        }
      }

      onFinisFailedMainForm = () => {
        this.openNotificationWithIcon(
          'error',
          i18next.t('w_form_finish_failed')
        )
      }

      cancelEdit = (index) => {
        let temp = [...this.translationsData]
        if(temp[index].id === 'New'){
          temp.splice(index, 1)
        } else {
          temp[index] = this.rowBeforeEdit
        }
        temp[index].isEdit = false
        this.translationsData = temp
        this.clearMainState()
      }



      //Other

    changePage = async (e) => {
        this.paginations.page = e.current
        await this.getTranslations()
    }

    changeSize = (current, size) => {
      this.paginations.pageSize = size
    } 

    setSortOrder = (type) => {
      this.filtersParams.sortOrder = type
    }
  
    setSortColumn = (type) => {
      this.filtersParams.sortColumn = type
    }

      openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
          placement: 'bottomRight'
        });
      };

      clearMainState = () => {
        this.isEditting = false
        this.isMainLoading = false
        this.btnLoading = {
          idx: null,
          type: null
        }
        this.rowBeforeEdit = null
      }


    setFilter = async (e) => {
      this.filtersParams.search = e.target.value
      await this.debouncedGetTranslations();
    }

    acceptFilter = async (type, data) => {
      this.filtersParams[type].filtered = data
      this.page = 1
      await this.getTranslations()
    }

    onClearFilter = async (type) => {
      this.filtersParams[type].filtered = []
      await this.getTranslations()
    }

    getTranslationsExcel = async () => {
      try{
        this.isExcelBtnLoading = true
        const params = {
          page: null,
          pageSize: null
        }

        const response = await axios({
          url: '/api/node/v1/translations/excel',
          responseType: 'blob',
          method: 'get',
          params
        })
        const blob = await response.data
        download(blob, `Translations ${dayjs().format('DD.MM.YYYY')}.xlsx`)
      } catch (error) {
        console.error(error)
        this.openNotificationWithIcon('error', i18next.t('w_error'))
      } finally {
        this.isExcelBtnLoading = false
      }
    }

    //Parsing

    changeParsingModalOpen = async () => {
      if(this.parsingModal.progress === 0 || this.parsingModal.progress > 100 || this.parsingModal.progress === 100) {
        this.parsingModal.open = !this.parsingModal.open
        if(!this.parsingModal.open){
          if(this.parsingModal.countOfAdded > 0 || this.parsingModal.countOfUpdated > 0) {
            await this.getTranslations()
          }
          this.clearParsingModalState(false)
        } 
      } else{
        this.openNotificationWithIcon('error', 'Wait for end of operation!')
      }
    }

    clearParsingModalState = (open) => {
      this.parsingModal = {
        open,
        progress: 0,
        data: [],
        countOfAdded: 0,
        countOfFailed: 0,
        countOfUpdated: 0,
        critError: null
      }
    }
    

    getTranslationExcelParseData = async () => {
      try{
       let response =  await axios.get(`/api/node/v1/translations/excel/parseData`)
       this.parsingModal = response.data.data
       if (response.data.data.critError) {
        this.failedSound.play();
      } else if(response.data.data.countOfFailed === response.data.data.data.length && response.data.data.progress === 100) {
        this.failedSound.play();
      } else if(response.data.data.progress === 100) {
        this.successSound.play();
      }
      } catch (error) {
        this.openNotificationWithIcon('error', i18next.t('w_error'))
      } 
    }

    postTranslationsExcel = async (excel) => {
      try{
        if(this.parsingModal.progress === 100){
          this.clearParsingModalState(true)
        }
        const formData = new FormData();
        formData.append('excel', excel);
        axios.post(`/api/node/v1/translations/excel`, formData)
        while(this.parsingModal.progress < 100){
          await this.getTranslationExcelParseData()
        }
        
      } catch (error) {
        this.openNotificationWithIcon('error', error)
      } 
    }

  }

export default TranslationsStore;
