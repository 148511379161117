import { makeAutoObservable, toJS } from 'mobx'
import api from '../utils/axiosAuthInterceptor'
import { notification } from 'antd'
import i18next from 'i18next'
import _, { cloneDeep, debounce, isEqual } from 'lodash'
const axios = api

class UpdatesStore {
  smart_sections = []
  smart_sections_opt = []
  updates = {
    data: [],
    user: {},
    isLoading: false,
    smart_section_id: null,
    sections_modules_id: null,
    searchValue: null,
    paginations: {
      page : 1,
      pageSize: 10,
      totalUpdates: null
    }
  }
  templates = {
    data: [],
  }
  createUpdate = {
    isOpen: false,
    tabs: ['w_global_update'],
    activeTabkey: 'w_global_update',
    isOpenConfirm: false,
    is_template: false,
    isNeedModules: false,
    isNeedUpdate: false,
    emptyDescIndexArray: [],
    editMode: {
      editType: '',
      indexHasModule: null,
      deleteIndex: null,
      deletedPhotosArray: [],
      prevUpdate: null
    },
    updates: [
      {
        theme: '',
        positions: [
          { type: 'description', id: 1 },
          { type: 'dragger', id: 2 },
        ],
      },
    ],
    progressBar: {
      percent: 0,
      status: 'normal',
      text: null,
    },
    postingUpdates: false,
  }
  updateCard = {
    isOpen: false,
    updatesIndex: null,
    theme: '',
    isLoading: false,
    isNeedUpdate: false
  }

  constructor() {
    this.debouncedGetUpdates = debounce(this.getUpdates, 500)
    makeAutoObservable(this, {}, { deep: true })
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  // UpdatesMain

  getUpdates = async () => {
    let updates = this.updates
    try {
      const params = {
        smart_section_id:this.updates.smart_section_id, 
        sections_modules_id: this.updates.sections_modules_id,
        searchValue: this.updates.searchValue?.trim(),
        page: this.updates.paginations.page,
        pageSize: this.updates.paginations.pageSize,
        lang: i18next.language ? i18next.language : 'EN',
      }
      updates.isLoading = true
      let response = await axios.get(`/api/node/v1/updates`, { params })
      let answer = response.data.data
      this.updates.paginations.totalUpdates = answer.count
      updates.data = answer.rows
      updates.user = answer.user
      this.templates.data = answer.templates
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      updates.isLoading = false
    }
  }

  postUpdates = async (body) => {
    let responseFirst
    try {
      responseFirst = await axios.post(`/api/node/v1/updates`, body)
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While trying save themes and descriptions!`
      )
      throw new Error()
    }
    return responseFirst.data.data
  }

  postUpdatesImages = async (postedUpdatesIds) => {
    const twoSecondsDelay = async () => {
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returning in 2s'
          resolve()
        }, 1000)
      )
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returning in 1s'
          resolve()
        }, 1000)
      )
      await new Promise((resolve) =>
        setTimeout(() => {
          this.createUpdate.progressBar.text = 'Returned!'
          this.createUpdate.progressBar.percent = 0
          resolve()
        }, 1000)
      )
    }
    const formData = new FormData()
    this.createUpdate.updates.forEach((update, updateIndex) => {
      update.positions.forEach((position, positionIndex) => {
        if (position.type === 'photo' && position.file) {
          formData.append(
            `updateImage`,
            position.file,
            `${postedUpdatesIds[updateIndex]}-${positionIndex}-${position.file.name}`
          )
        }
      })
    })
    try {
      this.createUpdate.progressBar.percent = 1
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve()
        }, 500)
      )
      await axios.post(`/api/node/v1/updates/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 89) / progressEvent.total
          )
          this.createUpdate.progressBar.percent = percentCompleted
          this.createUpdate.progressBar.text = `Uploading images`
        },
      })
      this.createUpdate.progressBar.percent = 100
      this.createUpdate.progressBar.status = 'success'
      this.createUpdate.progressBar.text = 'Upload complete!!!'
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve()
        }, 500)
      )
      await twoSecondsDelay()
    } catch (error) {
      this.createUpdate.progressBar.text = null
      this.createUpdate.progressBar.status = 'exception'
      await twoSecondsDelay()
      this.openNotificationWithIcon(
        'error',
        `${i18next.t(
          'w_error'
        )}: Cant upload images. Please try again or select other images! `
      )
      throw new Error()
    }
  }

  putUpdates = async (body) => {
    let responseFirst
    try {
      body.deletedPhotosArray = this.createUpdate.editMode.deletedPhotosArray
      responseFirst = await axios.put(`/api/node/v1/updates`, body)
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While trying update ${this.createUpdate.is_template ? 'template' : 'update'}!`
      )
      throw new Error()
    } finally {
      this.createUpdate.editMode.deletedPhotosArray = []
    }

    return responseFirst.data.data
  }

  deleteUpdate = async (id, index, type) => {
    try {
      this.createUpdate.editMode.deleteIndex = index
      await axios.delete(`/api/node/v1/updates/${id}`)
      this.openNotificationWithIcon('success', i18next.t('w_success'))
      if(type === 'updates') {
        await this.getUpdates()
        await this.getSmartSections()
      } else {
        this[type].data.splice(index, 1);
      }
      
    } catch (error) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: While deleting template!`
      )
      console.error(error)
    } finally {
      this.createUpdate.editMode.deleteIndex = null
    }
  }

//Other in main menu
  getSmartSections = async () => {
    try {
      let response = await axios.get(`/api/node/v1/updates/smartSections`)
      this.smart_sections = response.data.data
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  }

  getAllSmartSectionsWithoutFilters = async () => {
    try {
      let response = await axios.get(`/api/node/v1/updates/smartSections/all`)
      this.smart_sections_opt = response.data.data
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  }

  onChangeSeacrh = async (e) => {
    this.updates.searchValue = e.target.value;
    await this.debouncedGetUpdates();
  }

  onShowSizeChange = (current, pageSize) => {
    this.updates.paginations.pageSize = pageSize
  }

  onChangePagination = async (pageNumber) => {
    this.updates.paginations.page = pageNumber
    await this.getUpdates()
  }

  //Create Update
  createUpdateTriggerOpenModal = () => {
    this.createUpdate.isOpen = !this.createUpdate.isOpen
  }

  handleTabChange = (tab) => {
    this.createUpdate.activeTabkey = tab
  }

  addNewUpdate = () => {
    this.createUpdate.updates.push({
      theme: '',
      positions: [
        { type: 'description', id: 1 },
        { type: 'dragger', id: 2 },
      ],
    })
  }

  addFieldToForm = (updateIndex, fieldType) => {
    const newUpdates = [...this.createUpdate.updates]
    const currentPositions = newUpdates[updateIndex].positions
    const maxId = currentPositions.reduce(
      (max, pos) => (pos.id > max ? pos.id : max),
      0
    )

    const newField = { type: fieldType, id: maxId + 1 }
    currentPositions.push(newField)
    this.createUpdate.updates = newUpdates
  }

  onDragEnd(result, updateIndex) {
    const { source, destination } = result
    if (!destination) return

    const newUpdates = [...this.createUpdate.updates]
    const form = newUpdates[updateIndex]
    const [movedItem] = form.positions.splice(source.index, 1)
    form.positions.splice(destination.index, 0, movedItem)

    this.createUpdate.updates = newUpdates
  }

  beforeUploadPhotos(file, fileList, updateIndex, positionIndex) {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      this.openNotificationWithIcon(
        'warning',
        `File: ${file.name} - is not image!`
      )
      return false
    }
    fileList = fileList.filter(file => file.type.startsWith('image/'))
    const indexFileinFileList = fileList.findIndex(fileInFileList => fileInFileList.name === file.name)

    const newUpdates = [...this.createUpdate.updates]
    

    const maxId = newUpdates[updateIndex].positions.reduce(
      (max, pos) => (pos.id > max ? pos.id : max),
      0
    )

    const photoObj = { type: 'photo', id: maxId + 1, file: file }
    const insertPosition = positionIndex + indexFileinFileList + 1

    newUpdates[updateIndex].positions.splice(insertPosition, 0, photoObj)

    if (newUpdates[updateIndex].positions[positionIndex].type === 'dragger' && indexFileinFileList + 1 === fileList.length) {
      newUpdates[updateIndex].positions.splice(positionIndex, 1)
    }

    this.createUpdate.updates = newUpdates

    return false
  }

  onFinishGlobalUpdate = async (values, mainForm) => {
    try {
      this.createUpdate.postingUpdates = true
      let body = {
        smart_section_id: values.smart_section_id,
        sections_modules_ids: values?.sections_modules_ids
          ? values.sections_modules_ids
          : null,
        updates: this.createUpdate.updates,
        type: this.createUpdate.activeTabkey,
        is_template: this.createUpdate.is_template,
        is_edit_template: this.createUpdate.editMode.editType === 'template',
        lang: i18next.language
      }
      let postedUpdatesIds
      
      if (this.createUpdate.editMode.editType === 'update' || (this.createUpdate.editMode.editType === 'template' && this.createUpdate.is_template)) {
        body.update = this.createUpdate.updates[0]
        console.log(toJS(body.update.positions))
        postedUpdatesIds = await this.putUpdates(body)
      } else {
        postedUpdatesIds = await this.postUpdates(body)
      }

      const hasPhotoWithFile = this.createUpdate.updates.some((update) =>
        update.positions.some(
          (position) => position.type === 'photo' && position.file
        )
      )
      if (hasPhotoWithFile) {
        await this.postUpdatesImages(postedUpdatesIds)
      } else {
        this.openNotificationWithIcon('success', i18next.t('w_success'))
      }
      
      if(this.createUpdate.isOpenConfirm || this.createUpdate.editMode.editType) {
        this.createUpdate.isOpen = false;
        await Promise.all([this.getUpdates(), this.getSmartSections()])
        return 
      }
      this.clearCreateUpdateState(mainForm)
      this.createUpdate.isNeedUpdate = true
     
    } catch (error) {
      console.error(error)
      this.createUpdate.postingUpdates = false
      this.createUpdate.progressBar.status = 'normal'
      this.createUpdate.progressBar.text = null
      this.createUpdate.progressBar.percent = 0
    }
  }


  onCancelCreateUpdateModal = async (mainForm) => {
    if (this.createUpdate.postingUpdates) {
      this.openNotificationWithIcon(
        'error',
        `${i18next.t('w_error')}: Cant abort operation! Wait please!`
      )
      return
    }
    const values = mainForm.getFieldsValue()
    const hasValues = Object.values(values).some(Boolean)
    if(this.createUpdate.editMode.editType) {
      const stripHtml = (text) => {
        if (typeof text === "string") {
          return text.replace(/<\/?[^>]+(>|$)/g, "").trim(); 
        }
        return text;
      };
      
      let currentUpdate = toJS({
        ...toJS(this.createUpdate.updates[0]),
        smart_section_id: values.smart_section_id,
        positions: toJS(
          this.createUpdate.updates[0].positions.map((pos) => ({
            ...pos,
            en: stripHtml(pos.en),
            cn: stripHtml(pos.cn),
            ru: stripHtml(pos.ru),
          }))
        ),
      });
    
      
      const prevUpdate = toJS({
        id: this.createUpdate.editMode.prevUpdate.id,
        theme: this.createUpdate.editMode.prevUpdate[i18next.language],
        positions: toJS(this.createUpdate.editMode.prevUpdate.update_positions.map((pos) => ({
          ...pos,
          en: stripHtml(pos.en),
          cn: stripHtml(pos.cn),
          ru: stripHtml(pos.ru),
        }))), 
        smart_section_id: this.createUpdate.editMode.prevUpdate.smart_section_id
      });
      const areUpdatesEqual = isEqual(currentUpdate, prevUpdate);

      if(!areUpdatesEqual) {
        this.createUpdate.isOpenConfirm = true
        return
      }
    } else if(hasValues) {
      this.createUpdate.isOpenConfirm = true
      return
    }
    if (this.createUpdate.isNeedUpdate) {
        await Promise.all([this.getUpdates(), this.getSmartSections()])
      }
      this.createUpdate.isOpen = false
    }

  

  onClickEditUpdate = (update, mainForm) => {
    const clonedUpdate = cloneDeep(update)
    if (clonedUpdate.type === 'w_global_update') {
      let createUpdate = this.createUpdate
      createUpdate.activeTabkey = 'w_global_update'
      createUpdate.updates = [
        {
          theme: clonedUpdate[i18next.language],
          id: clonedUpdate.id,
          positions: clonedUpdate.update_positions,
        },
      ]
      const formValues = {
        smart_section_id: clonedUpdate.smart_section_id,
        theme_form_0: clonedUpdate[i18next.language],
      }

      clonedUpdate.update_positions.forEach((position) => {
        if (position.type === 'description') {
          formValues[`update=0_id=${position.id}`] = position[i18next.language]
        }
      })

      mainForm.setFieldsValue(formValues)

      if (clonedUpdate?.updates_sections_modules?.length > 0) {
        mainForm.setFieldsValue({
          sections_modules_ids: clonedUpdate.updates_sections_modules.map(
            (module) => module.sections_modules_id
          ),
        })
        createUpdate.isNeedModules = true
        createUpdate.editMode.indexHasModule = this.smart_sections_opt.findIndex(
          (section) => section.id === clonedUpdate.smart_section_id
        )
      }
      createUpdate.isOpen = true
      createUpdate.editMode.prevUpdate = update
      this.createUpdate = createUpdate
    }
  }

  clearCreateUpdateState = (mainForm) => {
    setTimeout(() => {
      mainForm.resetFields()
    }, 0)
    this.createUpdate = {
      ...this.createUpdate,
      tabs: ['w_global_update'],
      activeTabkey: 'w_global_update',
      isOpenConfirm: false,
      is_template: false,
      isNeedModules: false,
      emptyDescIndexArray: [],
      editMode:{
        editType: '',
        indexHasModule: null,
        deleteIndex: null,
        deletedPhotosArray:[],
      },
      isNeedUpdate: false,
      updates: [
        {
          theme: '',
          positions: [
            { type: 'description', id: 1 },
            { type: 'dragger', id: 2 },
          ],
        },
      ],
      progressBar: {
        percent: 0,
        status: 'normal',
        text: null,
      },
      postingUpdates: false,
    }
  }

  onFinishFailedGlobalUpdate = (e) => {
    this.openNotificationWithIcon(
      'warning',
      i18next.t('w_please_fill_all_required_fields')
    )
  }

  deleteUpdateAttribute = (updateIndex, positionIndex) => {
    const updatedUpdates = [...this.createUpdate.updates]
    const link = updatedUpdates[updateIndex].positions[positionIndex].file_link
    if (link) {
      this.createUpdate.editMode.deletedPhotosArray.push(link)
    }

    updatedUpdates[updateIndex] = {
      ...updatedUpdates[updateIndex],
      positions: updatedUpdates[updateIndex].positions.filter(
        (_, index) => index !== positionIndex
      ),
    }

    this.createUpdate.updates = updatedUpdates
  }

  globalUpdatesDeleteUpdate = (updateIndex) => {
    const updatedUpdates = [...this.createUpdate.updates]

    updatedUpdates.splice(updateIndex, 1)

    this.createUpdate.updates = updatedUpdates
  }

  onChangeTheme = (e, updateIndex) => {
    this.createUpdate.updates[updateIndex].theme = e.target.value
  }

  onChangeDescription = (content, updateIndex, positionIndex, mainForm) => {
    const isContentEmpty =
      !content || content.replace(/<[^>]*>/g, '').trim() === ''

      if (isContentEmpty) {
        if (this.createUpdate.updates?.[updateIndex]?.positions?.[positionIndex]?.value !== undefined) {
          this.createUpdate.updates[updateIndex].positions[positionIndex].value = null;
        } else {
          this.createUpdate.updates[updateIndex].positions[positionIndex][i18next.language] = null;
        }
    
        const fieldName = `update=${updateIndex}_id=${this.createUpdate.updates[updateIndex].positions[positionIndex].id}`;
    
        mainForm.setFieldsValue({
          [fieldName]: null,
        });
    
        mainForm.validateFields([fieldName]).catch(() => {});
    
        return;
      }
    if(this.createUpdate.editMode.editType) {
      this.createUpdate.updates[updateIndex].positions[positionIndex][i18next.language] =
      content
    } else {
      this.createUpdate.updates[updateIndex].positions[positionIndex].value =
      content
      
    }
    
    mainForm.setFieldsValue({
      [`update=${updateIndex}_id=${this.createUpdate.updates[updateIndex].positions[positionIndex].id}`]:
        content,
    })
  }

  //UpdateCard
  openUpdateCard = async (index, section, needReplace = true) => {
    this.updateCard.updatesIndex = index
    this.updateCard.theme = section
    this.updateCard.isOpen = true
    if (needReplace) {
      const params = new URLSearchParams(window.location.search)
      params.set('update_id', this.updates.data[index].id)
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`
      )
    }
    if(this.updates.data[index]?.updates_views?.length === 0){
      await this.postUpdatesView(this.updates.data[index].id)
      this.updateCard.isNeedUpdate = true
    }
    
  }
  onCancelUpdateCard = async () => {
    this.updateCard.isOpen = false
    const params = new URLSearchParams(window.location.search)
    params.delete('update_id')
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    )
    if(this.updateCard.isNeedUpdate) {
      await this.getUpdates()
      await this.getSmartSections()
    }
    this.updateCard.isNeedUpdate = false
  }

  postUpdatesView = async (updates_id) => {
    try {
      let body = { updates_id }
      await axios.post(`/api/node/v1/updates/views`, body)
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  }

  changeUpdateSlide = async (changer) => {
    changer === 'next' ?  this.updateCard.updatesIndex += 1 : 
    this.updateCard.updatesIndex -= 1
    const currentUpdate = this.updates.data[this.updateCard.updatesIndex]
    if (currentUpdate.updates_views.length === 0) {
      await this.postUpdatesView(currentUpdate.id)
      this.updateCard.isNeedUpdate = true
    }
   
    const params = new URLSearchParams(window.location.search)
    params.set('update_id', currentUpdate.id)
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    )
  }

}

export default UpdatesStore
