export const ORDER_STATUSES = {
  CLOSED: 'closed',
  DONE: 'done',
  OPEN: 'open',
}
export const TEMPLATE_TYPE = {
  PO: 'po',
  FINAL: 'final',
  PL: 'PLTemplate',
  INSPECTION: 'Inspection',
  FINAL_PL: 'finalPl',
  PART_PL: 'PartPL',
  FINAL_PART_PL: 'finalPartPl',
  PART_IR: 'PartIR',
}
export const TEMPLATE_AVAILABLE = {
  READ: 'read',
  ADD: 'add',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
}
export const TEMPLATE_GENERATION = {
  AUTO: 'auto',
}
export const TEMPLATE_EVENT = {
  REJECTION: 'rejection',
  RECOVERY: 'recovered',
  REVIEW: 'review',
}
export const DOWNLOAD_TEMPLATE_TYPE = {
  INSPECTION: 'inspection',
  PO: 'po',
  PRICES: 'prices',
  PL: 'packing',
}

export const ORDER_STATUS_ID = {
  OPENED: 1,
  CLOSED: 2,
}
