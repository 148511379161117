import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import download from 'downloadjs'
import i18next from 'i18next'
import api from "../utils/axiosAuthInterceptor";


const axios = api
const moment = require('moment')

class CompetitorsStore {
  constructor() {makeAutoObservable(this, {}, { deep: true })}
  competitorsMainData = null;
  isTableLoading=false
  currentUser = null
  page = 1
  prevStateOfRow=null
  filtersParams = {
    audits: {
      filtered: [],
      options: []
    },
    competitors_info: {
      filtered: [],
    },
    comments: {
      filtered: [],
    },
    files: {
      filtered: [],
    },
    productGroups:{
      filtered: [],
      options: []
    },
    competitorName:{
      filtered: '',
      options: []
    },
    suppliersNames: {
      filtered: [],
      options: [],
    },
  }
  allMarketsModal = {
    open: false,
    dupplicateCompetitor:{},
    existCompetitor: {},
    loading: false,
    popOverOpen: false
  }
  filesModal = {
    open: false,
    loading: false,
    data: [],
    competitorName: '',
    isAddedNewFile: false
  }
  commentsModal = {
    open: false,
    loading: false,
    data: [],
    competitorName: '',
    isAddedNewComment: false
  }
  competitorId = null
  limit = 10
  totalPages = null
  isBtnsDisabled=false
  isExcelBtnLoading=false
  downloadFileProgres = []


// get запросы

  getCompetitors = async () => {
    try{
      this.isTableLoading=true
      this.isBtnsDisabled=true
      const params = {
        page: this.page,
        limit:this.limit,
        competitorName: this.filtersParams.competitorName.filtered,
        competitors_info:this.filtersParams.competitors_info.filtered,
        comments:this.filtersParams.comments.filtered,
        files:this.filtersParams.files.filtered,
        productGroups:this.filtersParams.productGroups.filtered,
        suppliersNames: this.filtersParams.suppliersNames.filtered,
        audits: this.filtersParams.audits.filtered,
      }
      const response = await axios.get(`/api/node/v1/competitors`, {
        params
      })

      this.competitorsMainData =  response.data.data.rows
      this.currentUser = response.data.data.currentUser
      this.totalPages = response.data.data.count
    }
    catch (error){
      this.openNotificationWithIcon('error', `Get data error:${error}`)
    }
    finally {
      this.isTableLoading=false
      this.isBtnsDisabled=false
      this.prevStateOfRow=null
    }
  }

  getAllProductGroups = async () =>{
    try{
      const response = await axios.get(`/api/node/v1/competitors/productGroups`)
      this.filtersParams.productGroups.options = response.data.data
    } catch (error) {
        this.openNotificationWithIcon('error', `Get all product groups error:${error}`)
    }
  }

  getAllCompetitorsNames = async () => {
    try {
      const response = await axios.get(`/api/node/v1/competitors/names`);
      let arr = response.data.data.map(el => el.name)
      let uniqueArr = new Set(arr)
      uniqueArr = [ ...uniqueArr]
      this.filtersParams.competitorName.options = uniqueArr.map(
        el => ({
        value: el,
        label: el
      }))
    } catch (error) {
      this.openNotificationWithIcon('error', `Get all competitors names error:${error}`);
    }
  }

  getAllSuppliersNames = async () => {
    try {
      let response = await axios.get('/api/node/v1/factories/abbreviations');
      this.filtersParams.suppliersNames.options = response.data.map(
        el => ({
        value: el.id,
        label: el.abbreviation
      }))
    } catch (error) {
      this.openNotificationWithIcon('error', `Get all suppliers names error`)
    }
  }

  getAllData = async () => {
    await this.getCompetitors()
    await this.getAllSuppliersNames()
    await this.getAllCompetitorsNames()
    await this.getAllProductGroups()
  }

// Основные row функции таблицы

cancelRow = (row, index) => {
  let temp = [...this.competitorsMainData]
  if(row.isNew){
    temp.splice(index,1)
  }
  else{
    temp[index]=this.prevStateOfRow
  }
  temp[index].isEdit = false
  this.isBtnsDisabled=false
  this.competitorsMainData = temp
}

  deleteCompetitor = async (id) => {
    try{
      await axios.delete(`/api/node/v1/competitors/${id}`)
      await this.getAllData()
      this.openNotificationWithIcon('success',  i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  saveCompetitor = async (body) => {
    if(this.prevStateOfRow) {
      body.id = this.prevStateOfRow.id
    }
      console.log(body)
      try{
        this.isTableLoading=true
        await axios.post(`/api/node/v1/competitors`, body)
        this.openNotificationWithIcon('success',  i18next.t('w_success'))
        await this.getAllData()
      } catch (error) {
        if(error.response && error.response.data.errors === 'w_need_unique_competitor_name') {
          this.openNotificationWithIcon('error', i18next.t('w_need_unique_competitor_name'))
        }
        else if(error.response && error.response.data.errors.errorName === 'w_add_existing_competitor') {
          this.allMarketsModal.existCompetitor = error.response.data.errors.data
          this.allMarketsModal.dupplicateCompetitor = body
          this.allMarketsModal.open = true
        }
        else {
          console.error(error)
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        }
        this.isTableLoading = false
      }
  }

  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }


  editRow = async (index) => {
    let temp = [...this.competitorsMainData]
    let el = this.competitorsMainData[index]
    this.prevStateOfRow = JSON.parse(JSON.stringify(this.competitorsMainData[index]))
    el.isEdit=true
    this.isBtnsDisabled=true
    this.competitorsMainData = temp
  }

  addRow = () => {
    let temp = [...this.competitorsMainData]
    this.isBtnsDisabled=true

    temp.unshift({
      name: '',
      productGroups: [],
      suppliersNames: [],
      audits: [],
      competitors_info: null,
      isEdit: true,
      isNew: true,
    })
    this.competitorsMainData = temp
  }


  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }
  timeWithoutHours = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD')
  }

  changePage = (e) => {
    this.page = e.current
    this.getCompetitors()
  }

  changePageSize = async (size) => {
    this.limit = size
  }

    setFilter =(value) => {
      this.filtersParams.competitorName.filtered = value;
    }

  onSearch = (value) => {
    this.filtersParams.competitorName.filtered = value
    this.page=1
    this.getCompetitors()
  }

  onKeyDownAuto=(e)=>{
    if (e.key === 'Enter') {
      this.getCompetitors()
    }
  }

  acceptFilter = (type,data) => {
    this.filtersParams[type].filtered = data
    this.page = 1
    this.getCompetitors()
  }

  clearFilters = (isgetCompetitorsNeeded=true) => {
    for (let filter in this.filtersParams) {
        this.filtersParams[filter].filtered = []
    }
    if(isgetCompetitorsNeeded){
      this.page=1
      this.getCompetitors()
    }
  }

   onClearFilter=(type)=>{
    this.filtersParams[type].filtered=[]
    this.getCompetitors()
  }

// Other

changeSize = (current, size) => {
  this.limit = size
}

  showFileInNewWindow = (file) => {
    const fileExtension = file?.filename.split('.').pop().toLowerCase();
    const openInNewTabExtensions = ['jpg', 'png', 'jpeg', 'svg', 'pdf', 'doc', 'docx'];
    const fileURL = `${process.env.REACT_APP_SERVER_ADDRESS}/${file.link}`;
    if (openInNewTabExtensions.includes(fileExtension)) {
      if (
        fileExtension === 'pdf' || fileExtension === 'jpg' ||
        fileExtension === 'png' || fileExtension === 'jpeg' ||
        fileExtension === 'svg'
      ) { window.open(`${fileURL}`, "_blank") }
      else if (fileExtension === 'doc' || fileExtension === 'docx') {
        window.open(`${process.env.REACT_APP_URL}/react/competitors/doc?fileURL=${encodeURIComponent(fileURL)}&fileId=${encodeURIComponent(file.id)}&fileName=${encodeURIComponent(file.filename)}`, "_blank")
      }
      else {
        this.downloadFile(file);
      }
    } else {
      this.downloadFile(file);
    }
  }

  exportCompetitorsToExcel = async () => {
    try{
      this.isExcelBtnLoading = true
      const params = {
        competitorName: this.filtersParams.competitorName.filtered,
        competitors_info:this.filtersParams.competitors_info.filtered,
        comments:this.filtersParams.comments.filtered,
        files:this.filtersParams.files.filtered,
        productGroups:this.filtersParams.productGroups.filtered,
        suppliersNames: this.filtersParams.suppliersNames.filtered,
        audits: this.filtersParams.audits.filtered,
      }

      const response = await axios({
        url: '/api/node/v1/competitors/excel',
        responseType: 'blob',
        method: 'get',
        params,
      })
      const blob = await response.data
      download(blob, `Competitors ${moment().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }

  //AllMarketsModal
  closeAllMarketsModalOpen = async () => {
    await this.getCompetitors()
    this.allMarketsModal.popOverOpen = false
    this.allMarketsModal.open = false
  }

  onOpenChangePopoverAllMarketsModal = (open) => {
    this.allMarketsModal.popOverOpen = open
  }

  clickYesAllMarketModal = async () => {
    try{
      this.allMarketsModal.loading = true
      const id = this.allMarketsModal.existCompetitor.id
      await axios.put(`/api/node/v1/competitors/allMarkets/${id}`)
      this.allMarketsModal.open = false
      await this.getCompetitors()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      this.allMarketsModal.loading = false
    }
  }

  //File modal
  getCompetitorsFiles = async () => {
    try{
      const id = this.competitorId
      this.filesModal.loading = true
      const response = await axios.get(`/api/node/v1/competitors/${id}/files`)
      this.filesModal.data = response.data.data
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.filesModal.loading = false
    }
  }

  filesModalOpenClose = async (competitor, openClose) => {
    if(openClose === 'open'){
      this.filesModal.data = []
      this.filesModal.open = true
      this.competitorId = competitor.id
      this.filesModal.competitorName = competitor.name
      await this.getCompetitorsFiles()
      this.filesModal.isAddedNewFile = false
    } else {
      this.filesModal.open = false
        if(this.filesModal.isAddedNewFile) {
          await this.getCompetitors()
        }
    }
  }

  downloadFile = async (file) => {
    try{
      const response = await axios.get(
        `/api/node/v1/competitors/file/${file.id}`,
        {
          responseType: 'blob',
        }
      )
      download(response.data, file.filename)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  saveFile = async (file) => {
    try{
      const id = this.competitorId
      const formData = new FormData()
      formData.append(`competitorsFile`, file)
      this.filesModal.loading = true
      let response = await axios.post(`/api/node/v1/competitors/${id}/file`, formData)
      this.filesModal.data.unshift(response.data.data)
      this.filesModal.isAddedNewFile = true
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.filesModal.loading = false
      return false
    }
  }

  deleteFile = async (file) => {
    try{
      this.filesModal.loading = true
      const id = file.id
      await axios.delete(`/api/node/v1/competitors/file/${id}`)
      await this.getCompetitorsFiles()
      this.filesModal.isAddedNewFile = true
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.filesModal.loading = false
    }
  }

  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(
        `${link}/${file.id}`,
        {
          responseType: 'blob',
          params: { id: file.id },
          onDownloadProgress: (progressEvent) => {
            const loaded = progressEvent.loaded
            const total = progressEvent.total
            const progressPercentage = Math.round((loaded / total) * 100)
            this.load = progressPercentage
            const fileIndex = this.downloadFileProgres.findIndex(
              (el) => el.fileId === file.id
            )

            if (fileIndex !== -1) {
              this.downloadFileProgres[fileIndex].progress = progressPercentage
              this.downloadFileProgres[fileIndex].status = true
            }
            this.downloadFileProgres = [...this.downloadFileProgres]
          },
        }
      )
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }

  //Comment modal
  getCompetitorsComments = async () => {
    try{
      this.commentsModal.loading = true
      const id = this.competitorId
      const response = await axios.get(
        `/api/node/v1/competitors/${id}/comments`
      )
      this.commentsModal.data = response.data.data
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.commentsModal.loading = false
    }
  }

  commentsModalOpenClose = async (competitor, openClose) => {
    if(openClose === 'open'){
      this.commentsModal.data = []
      this.commentsModal.competitorName = competitor.name
      this.commentsModal.open = true
      this.competitorId = competitor.id
      await this.getCompetitorsComments()
      this.commentsModal.isAddedNewComment = false
    } else {
      this.commentsModal.open = false
        if(this.commentsModal.isAddedNewComment) {
          await this.getCompetitors()
        }
    }
  }

  postCompetitorComment = async (values) => {
    try{
      this.commentsModal.loading = true
      const id = this.competitorId
      const comment = values.comment
      const response = await axios.post(
        `/api/node/v1/competitors/${id}/comment`,
        { comment }
      )
      this.commentsModal.data.unshift(response.data.data)
      this.commentsModal.comment = ''
      this.commentsModal.isAddedNewComment = true
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.commentsModal.loading = false
    }
  }


  onFinishFailedCommentsModal = async () => {
    this.openNotificationWithIcon('error', i18next.t('w_form_finish_failed'))
  }




}

export default CompetitorsStore

