import { makeAutoObservable } from 'mobx'
import api from '../utils/axiosAuthInterceptor'
import download from 'downloadjs'
import { notification } from 'antd'
import i18next from 'i18next'
import dayjs from 'dayjs'
const axios = api

class BuyersStore {
  data = []
  isAdmin = false
  user = null
  state ={
    loading: false,
    isBtnsDisabled: false,
    prevStateOfRow: null,
    excelLoad: false
  }
  paginations = {
    page : 1,
    pageSize: 10,
    totalBuyers: null
}
  requisitesModalState = {
    data: [],
    buyersID: null,
    isModalOpen: false,
    state: 'common',
    prevStateOfCard: null,
    isStateLoading: false,
    activekey: null,
    idxPopover:null,
    newOpen:false
  }
  emailModal = {
    loading: false,
    open: false
  }
  signaturesModal = {
    data: [],
    name: null,
    choosenID: null,
    buyersID: null,
    isNeedUpdate: false,
    isOpen: false,
    isLoading: false,
    isBtnLoading: false
  }
  filtersParams = {
    name: {
      filtered: [],
      options: [],
    },
    buyers_country: {
      filtered: [],
      options: [],
    },
    sort:{
      sortColumn:null,
      sortOrder:null,
    }
  }
  

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  // Основные запросы к серверу
  getBuyers = async () => {
    try {
      this.state.loading = true
      const params = {
        page: this.paginations.page,
        pageSize: this.paginations.pageSize,
        name: this.filtersParams.name.filtered,
        buyers_country: this.filtersParams.buyers_country.filtered,
        sortOrder: this.filtersParams.sort.sortOrder,
        sortColumn: this.filtersParams.sort.sortColumn,
      }
      let response = await axios.get(`/api/node/v1/buyers`, {params})
      this.data = response.data.data.rows
      this.paginations.totalBuyers = response.data.data.count
      this.isAdmin = response.data.data.current_user.userDepartment == 5 ?
      true : false
      this.filtersParams.name.options = response.data.data.buyersNamesOpt
      this.filtersParams.buyers_country.options = response.data.data.buyersCountryOpt

    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearState()
    }
  }

  deleteBuyer = async (id) => {

    try {
      await axios.delete(`/api/node/v1/buyers/${id}`)
      await this.getBuyers()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      this.clearState()
    }
  }

  updateBuyer = async (row) => {
    const body = row

    try {
      await axios.put(`/api/node/v1/buyers`, body)
      await this.getBuyers()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      this.clearState()
      row.isEdit = false
    }
  }

  createBuyer = async (row) => {
    const body = row

    try {
      await axios.post(`/api/node/v1/buyers`, body)
      await this.getBuyers()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } finally {
      this.clearState()
      row.isEdit = false
    }
  }

  getBuyersExcel = async () => {
    try {
        this.state.excelLoad = true
        const params = {
            name: this.filtersParams.name.filtered,
            order: this.filtersParams.sort.sortOrder,
            orderBy: this.filtersParams.sort.sortColumn,
        };
        const response = await axios({
          url: '/api/node/v1/buyers/excel',
          responseType: 'blob',
          method: 'get',
          params,
        })
        const blob = await response.data
        download(blob, `Buyers ${dayjs().format('DD.MM.YYYY')}.xlsx`)
        this.openNotificationWithIcon('success', i18next.t('w_success'));
    } catch (error) {
        console.error(error);
        this.openNotificationWithIcon('error', i18next.t('w_error'));
    } finally {
        this.state.excelLoad = false
    }
};

  

  // Фильтры

  acceptFilter = (type, data) => {
    this.filtersParams[type].filtered = data
    this.getBuyers()
  }

  setSortOrder=(type)=>{
   this.filtersParams.sort.sortOrder=type
}

  setSortColumn=(type)=>{
   this.filtersParams.sort.sortColumn=type
  }

 clearFilters = () => {
   for (let filter in this.filtersParams) {
     if (this.filtersParams[filter].filtered){
       this.filtersParams[filter].filtered=[]
     }
     else{
       this.filtersParams[filter].sortColumn=undefined
       this.filtersParams[filter].sortOrder=undefined
     }
   }
  this.clearState()

 }

   onClearFilter=(type)=>{
     this.filtersParams[type].filtered=[]
     this.getBuyers()
  }

  // Операции в основной таблице

  onFinishMainForm = async (values) => {
    if(this.state.prevStateOfRow) {
      values.id = this.state.prevStateOfRow.id
      await this.updateBuyer(values)
    }
    else {
      await this.createBuyer(values)
    }
  }

  onFinisFailedhMainForm = () => {
    this.openNotificationWithIcon('error', i18next.t('w_form_finish_failed'))
  }


  editRow = async (index) => {
    this.state.isBtnsDisabled = true
    this.state.prevStateOfRow = JSON.parse(JSON.stringify(this.data[index]))
    let temp = [...this.data]
    temp[index].isEdit = true
    this.data = temp
  }

  cancelRow = (row, index) => {
    let temp = [...this.data]

    if (row.isNew) {
      temp.shift()
    } else {
      temp[index] = this.state.prevStateOfRow
    }

    this.data = temp
    this.state.isBtnsDisabled = false
  }

  addRow = () => {
    this.state.isBtnsDisabled = true
    let temp = [ ...this.data]
    temp.unshift({
      code: '',
      full_company_name: '',
      address: '',
      web: '',
      company_seal_signature:[],
      buyers_company_seal_signatures: [],
      buyers_bank_requisites: [],
      isEdit: true,
      isNew: true,
    })
    this.data = temp
  }

  copyRowData = (row) => {
    const excludedKeys = ['id']
    const keysToCopy = Object.keys(row).filter(
      (key) => !excludedKeys.includes(key)
    )

    if (keysToCopy.length > 0) {
      const rowData = keysToCopy.map((key) => row[key]).join(' ')

      const textarea = document.createElement('textarea')
      textarea.value = rowData
      document.body.appendChild(textarea)
      textarea.select()
      textarea.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.openNotificationWithIcon('success', 'Row data copied to clipboard!')
    } else {
      this.openNotificationWithIcon('error', 'No data to copy')
    }
  }

  // Requisites modal

  getRequisites = async () => {
    
    try {
      this.requisitesModalState.isStateLoading = true
      const response = await axios.get(
        `/api/node/v1/buyers/requisites/${this.requisitesModalState.buyersID}`
      )
      this.requisitesModalState.data = response.data.data
      this.clearRequisitesState(this.requisitesModalState.activekey)
        if(this.requisitesModalState.data?.length === 0) {
          this.handleAddRequisitesModalBtn()
        }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        console.error(error)
        this.openNotificationWithIcon('error', 'Error while geting requisites data')
      }
      this.requisitesModalState.isStateLoading = false
    } 
  }

  createRequisite = async (body) => {
    try {
      this.requisitesModalState.isStateLoading = true
      const response = await axios.post( `/api/node/v1/buyers/requisites`, body)
      this.requisitesModalState.activekey = response.data.data
      await this.getRequisites()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.requisitesModalState.isStateLoading = false
    }
  } 

  updateRequisite = async (body) => {
    try {
      this.requisitesModalState.isStateLoading = true
      let id = this.requisitesModalState.activekey
      await axios.put( `/api/node/v1/buyers/requisites/${id}`, body)
      await this.getRequisites()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.requisitesModalState.isStateLoading = false
    }
  } 

  deleteRequisite = async (id) => {
    try{
      this.requisitesModalState.isStateLoading = true
      await axios.delete( `/api/node/v1/buyers/requisites/${id}`)
      await this.getRequisites()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.requisitesModalState.isStateLoading = false
    }
  }

  onFinishrequisitesForm = async (values) => {
    let editObj = this.requisitesModalState.data.find(
      req => req.isEdit === true
    )
    const body = {
      ...values,
      buyers_id: this.requisitesModalState.buyersID,
      requisite_name: editObj.requisite_name,
      is_main: values.chekbox
    }
    if(this.requisitesModalState.activekey === 'New'){
      await this.createRequisite(body)
    }
    else {
      await this.updateRequisite(body)
    }
    if(values.is_main !== 0) await this.getBuyers()
  }

  onFinishFailedrequisitesForm = ({errorFields}) => {
    let errorFieldsNames = errorFields.map(error => error.name).join(',')
    this.openNotificationWithIcon('error', i18next.t('w_form_finish_failed') + `:${errorFieldsNames}`)
  }

  openRequisitesModal = async (id) => {
    this.requisitesModalState.buyersID = id
    await this.getRequisites()
    this.requisitesModalState.isModalOpen = true
  }

  cancelEditRequisitesModal = () => {
    if (this.requisitesModalState.prevStateOfCard) {
      let idx = this.requisitesModalState.data.findIndex((el) => el.isEdit === true)
      this.requisitesModalState.data[idx] = { ...this.requisitesModalState.prevStateOfCard }
    } else {
      this.requisitesModalState.data.pop()
    }
    this.clearRequisitesState()
  }

  toggleEmailModal = () => {
    this.emailModal.open = !this.emailModal.open
  }

  handlePanelChange = (key) => {
    this.requisitesModalState.activekey = key
  }

  editDetail = (idx, event) => {
    event.stopPropagation()
    this.requisitesModalState.prevStateOfCard = { ...this.requisitesModalState.data[idx] }
    this.requisitesModalState.data[idx].isEdit = true
    this.requisitesModalState.state = 'editing'
    this.requisitesModalState.activekey = this.requisitesModalState.data[idx].id
  }

  editDetailValues = (detailId, type, value) => {
    let temp = [...this.requisitesModalState.data]
    let el = temp.find((elem) => elem.id === detailId)
    el[type] = value
    this.requisitesModalState.data = temp
  }

  sendRequisitesEmail = async (body) => {
    let id = this.requisitesModalState.buyersID

    try{
      this.emailModal.loading = true
      await axios.post(`/api/node/v1/buyers/requisites/excel/${id}`, body)
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.emailModal.loading = false
      this.emailModal.open = false
    }
  }

  onFinisFailedhEmailForm = ({ errorFields }) => {
    let errorFieldsNames = errorFields.map(error => error.name).join(',')
    this.openNotificationWithIcon('error', i18next.t('w_form_finish_failed') + `:${errorFieldsNames}`)
  }

  closeRequisitesModal = () => {
    this.requisitesModalState.isModalOpen = false
    this.clearRequisitesState()
  }

  handleCurrency = (value, detail) => {
    let idx = this.requisitesModalState.data.findIndex((el) => detail.id === el.id)
    this.requisitesModalState.data[idx].currency = value
  }

  handleAddRequisitesModalBtn = () => {
    const newDetail = {
      id: 'New',
      currency: 'USD',
      beneficiary_account: null,
      beneficiary_address: null,
      beneficiary_details: null,
      beneficiary_bank_address: null,
      beneficiary_bank_name: null,
      beneficiary_name: null,
      cnaps: null,
      bank_comments: null,
      purpose_of_payment: null,
      swift: null,
      isEdit: true,
      is_main: false,
      requisite_name: null,
      beneficiary_account: null,
    }
    this.requisitesModalState.activekey = 'New'
    this.requisitesModalState.state = 'editing'
    this.requisitesModalState.data.push(newDetail)
  }

  cancelPopover=(state)=>{
    this[state].newOpen=false
    this[state].idxPopover=null
  }

  handleDeletePopover= (state, idx) => {
    this[state].idxPopover=idx
  }
  onOpenChangePopover=(state, newOpen)=>{
    this[state].newOpen=newOpen
  }

  clearRequisitesState = (activekey = null) => {
    this.requisitesModalState = {
      ...this.requisitesModalState,
      state: 'common',
      prevStateOfCard: null,
      isStateLoading: false,
      newOpen:false,
      idxPopover:null,
      activekey
    }
  }
  // SignaturesModal
  getSignatures = async () => {
    try{
      this.signaturesModal.isLoading = true
      const id = this.signaturesModal.buyersID
      const response = await axios.get(`/api/node/v1/buyers/signatures/${id}`)
      this.signaturesModal.data = response.data.data.rows
      this.signaturesModal.name = response.data.data.buyer.name
      this.signaturesModal.choosenID = response.data.data.rows.find(obj=>obj.choosen === true)?.id
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.signaturesModal.isLoading = false
    }
  }

  uploadSignature = async (options) => {
    try{
      this.signaturesModal.isLoading = true
      const id = this.signaturesModal.buyersID
      if(this.signaturesModal.data.length === 0){
        this.signaturesModal.isNeedUpdate = true
      }
      const formData = new FormData();
      formData.append('signature', options.file); 
      await axios.post(`/api/node/v1/buyers/signatures/${id}`, formData)
      await this.getSignatures()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.signaturesModal.isLoading = false
    }
  }

  updateSignature = async () => {
    const id = this.signaturesModal.choosenID
    const body = {
      buyers_id: this.signaturesModal.buyersID
    }

    try{
      await axios.put(`/api/node/v1/buyers/signatures/${id}`, body)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  downloadSignature = async (signature) => {
    try{
      let response = await axios.get(`/api/node/v1/buyers/signatures`,  {
        params: {
            file_link: signature.link,
            file_name: signature.filename
        },
        responseType: 'blob'
    })
    download(response.data, signature.filename)
    } catch (error){
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  deleteSignature = async (signature) => {
    try{
      this.signaturesModal.isLoading = true
      const {id} = signature
      const params = {
        link: signature.link,
      }
      await axios.delete(`/api/node/v1/buyers/signatures/${id}`, {params})
      await this.getSignatures()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error){
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.signaturesModal.isLoading = false
    }
  }

  openSignaturesModal = async (id) => {
    this.signaturesModal.buyersID = id
    await this.getSignatures()
    this.signaturesModal.isOpen = true
  }

  closeSignaturesModal = () => {
    this.signaturesModal.isOpen = false
    this.signaturesModal.isNeedUpdate = false
  }

  onChangeRadio = (id) => {
    this.signaturesModal.choosenID = id
    this.signaturesModal.isNeedUpdate = true
  } 

  // Остальное

  changeSize = (current, size) => {
    this.paginations.pageSize = size
  } 

  changePage = (e) => {
    this.paginations.page = e.current
    this.getBuyers()
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  clearState = () => {
    this.state = {
        loading: false,
        isBtnsDisabled: false,
        prevStateOfRow: null,
        excelLoad: false
      }
  }
  

}

export default BuyersStore
