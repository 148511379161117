import { makeAutoObservable, observable } from 'mobx'
import { notification } from 'antd'
import api from '../utils/axiosAuthInterceptor'
import i18next from 'i18next';
import download from 'downloadjs';
import { debounce } from 'lodash'

const axios = api

class InstructionsStore {
  constructor() {
    this.debouncedGetInstructions = debounce(this.getInstructions, 500);
    makeAutoObservable(this, {newModule: observable}, { deep: true })
  }

  state = null
  instructions = []
  user = null
  modulesOpt = []
  filelist = []
  downloadFileProgres = []
  filtersParams = {
    file_name:{
      options:[],
      filtered: ''
    }
  }

  uploadModal = {
    open: false,
    newModule: '',
    selectOpen: false,
    loading: false,
    type: 'link'
  }
  toolTip = {
    editting: false,
    text: '',
  }
  archive = {
    open: false,
    instructions: [],
  }

  //Main CRUD operations

  getInstructions = async () => {
    try{
      this.state = 'mainLoad'
      const params = {
        isArchive: this.archive.open ? 1 : 0,
        file_name: this.filtersParams.file_name.filtered
      }
      let response = await axios.get('/api/node/v1/instructions', {params})
      this.instructions = this.archive.open ?  this.instructions : response.data.data.rows
      this.modulesOpt = response.data.data.allModules
      this.user = response.data.data.user
      this.filtersParams.file_name.options = response.data.data.allFileNames
      this.archive.instructions = this.archive.open ? response.data.data.rows : this.archive.instructions
      this.filelist = response.data.data.rows.map(instruction => ({
        ...instruction,
        files: instruction.files
        .filter(file => file.type !== 'link')
        .map(file => ({
          ...file,
          original_name: file.file_name,
          file_link: file.file_link,
          type: 'application'
        }))
      })
      )
      await this.getTooltip()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error',
      'Error while getting instructions!'
        )
    } finally {
      this.state = null
    }
  }

  //Instructions files

  restoreInstructionFile = async (id) => {
    try{
       await axios.patch(`/api/node/v1/instructions/files/restore/${id}`)
       this.openNotificationWithIcon('success', i18next.t('w_success'))
       await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }
  updateDateinstructionFile = async (file) => {
    try{
      const body = {
        id: file.id,
        file_name: file.file_name
      }
      await axios.patch(`/api/node/v1/instructions/files`, body)
      this.openNotificationWithIcon('success', i18next.t('w_success'))
      await this.getInstructions()
   } catch (error) {
     console.error(error)
     this.openNotificationWithIcon('error', i18next.t('w_error'))
   }
  }

  downloadDocument = async (file) => {
    try{
      let response = await axios.get(`/api/node/v1/instructions/files/${file.id}`, {
        responseType: 'blob'
    })
      download(response.data, file.file_name)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon( 'error', i18next.t('w_download_error'))
    }
  }

  deleteInstructionFile = async (id, isForceDelete) => {
    try{
      const data = {
        id,
        isForceDelete
      }
      await axios.delete(`/api/node/v1/instructions/files`, {data})
      this.openNotificationWithIcon('success', i18next.t('w_success'))
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error', i18next.t('w_error'))
    }
  }

//ToolTip

  getTooltip = async () => {
    try{
      const module = 'instructions'
      const type = 'main_tooltip'
      let response = await axios.get(`/api/node/v1/handbooksCommon/tooltips/${module}/${type}`)
      this.toolTip.text = response.data.data?.text
      if(!this.toolTip.text){
        this.toolTip.text = 'Combination of CTRL + F4 will help you find text!'
      }
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error', i18next.t('w_error'))
    }
  }

  postOrUpdateTooltip = async () => {
    try{
      const body = {
          module:'instructions',
          type: 'main_tooltip',
          text: this.toolTip.text
      }
      await axios.post(`/api/node/v1/handbooksCommon/tooltips`, body)

    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error', i18next.t('w_error'))
    }
  }

  onChangeTooltipInput = (e) => {
    this.toolTip.text = e.target.value
  }

  onOpenChangeTooltip = async (open) => {
    if(!open && this.toolTip.editting){
      this.toolTip.editting = false
      await this.postOrUpdateTooltip()
    }
  }

  onClickTooltip = () => {
    this.toolTip.editting = !this.toolTip.editting
  }

  // UploadModal



  saveInstructionsLink = async (values) => {
    values.file_name = values.file_name.trim()
    const body = values
    try{
      this.uploadModal.loading = true
      await axios.post(`/api/node/v1/instructions/files/link`, body)
      this.uploadModal.loading = false
      this.uploadModal.open = false
      this.openNotificationWithIcon('success', i18next.t('w_save_succeess'))
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error_while_saving'))
      this.uploadModal.loading = false
    }
  }

  saveInstructionsFile = async (values) => {
    try{
      let file_name = values.file_name ? values.file_name?.trim() :
      values.instruction.file.name.split('.').slice(0, -1).join('.');
      if(file_name.length === 0) {
        this.openNotificationWithIcon('error', i18next.t('w_file_without_name'))
        return
      }
      this.uploadModal.loading = true
      let module_id = values.module_id
      let data = new FormData();
      data.append('instruction', values.instruction.file);
      await axios.post(`/api/node/v1/instructions/files/${module_id}/${file_name}`, data)
      this.uploadModal.loading = false
      this.uploadModal.open = false
      this.openNotificationWithIcon('success', i18next.t('w_save_succeess'))
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error_while_saving'))
      this.uploadModal.loading = false
    }
  }


  changeUlpoadModalState = (type, value) => {
    let temp = { ...this.uploadModal}
    temp[type] = value
    this.uploadModal = temp
  }

  //Filters
  onSearch = async (value) => {
    this.filtersParams.file_name.filtered = value
    await this.getInstructions()
  }

  setFilter = (value) => {
    this.filtersParams.file_name.filtered = value;
    this.debouncedGetInstructions()

  }

  onKeyDownAuto= async (e)=>{
    if (e.key === 'Enter') {
      await this.getInstructions()
    }
  }

  //Archive

  archiveInstruction = async (id) => {
    try{
      await axios.delete(`/api/node/v1/instructions/files/archive/${id}`)
      await this.getInstructions()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  triggerOpenArchive = async () => {
    this.archive.open = !this.archive.open
    this.filtersParams.file_name.filtered = ''
    if(!this.archive.open){
      await this.getInstructions()
    }
  }

  //Other

openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    placement: 'bottomRight',
  })
}

getFile = async (file, link) => {
  try {
    const fileObject = {
      fileId: file.id,
      type: file.type,
      filename: file.original_name,
      progress: 0,
      status: false,
      data: [],
    }
    this.downloadFileProgres.push(Object.assign({}, fileObject))

    const data = await axios.get(
      `${link}/${file.id}`,
      {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          // Находим объект файла в массиве и обновляем его прогресс
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      }
    )
    const fileIndex = this.downloadFileProgres.findIndex(
      (el) => el.fileId === file.id
    )
    if (fileIndex !== -1) {
      this.downloadFileProgres[fileIndex].data = data
      this.downloadFileProgres[fileIndex].status = false
    }
    return data
  } catch (error) {
    console.error(`Error downloading file`)
    // Устанавливаем статус файла в "exception"
    const fileIndex = this.downloadFileProgres.findIndex(
      (el) => el.fileId === file.id
    )
    if (fileIndex !== -1) {
      this.downloadFileProgres[fileIndex].status = 'exception'
    }
    this.downloadFileProgres = [...this.downloadFileProgres]
  }
}

}

export default InstructionsStore
