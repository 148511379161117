import {makeAutoObservable} from "mobx";
import api from '../utils/axiosAuthInterceptor';
import {notification} from "antd";
import i18next from "i18next";
const axios = api

class UpdatesStore {
  updates = {
    data: [],
    user:{},
    isLoading: false,
  }
  smart_sections = []

 

  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  // UpdatesMain

  getUpdates = async () => {
    let updates = this.updates
    try{
      updates.isLoading = true
      let response = await axios.get(`/api/node/v1/updates`,)
      let answer = response.data.data
      updates.data = answer.rows
      updates.user = answer.user
      updates.tabs = answer.sections
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      updates.isLoading = false
    }
  }

  getSmartSections = async () => {
    try{
      let response = await axios.get(`/api/node/v1/updates/smartSections`)
      this.smart_sections = response.data.data
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }



}

export default UpdatesStore
