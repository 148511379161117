import { makeAutoObservable, runInAction, toJS } from 'mobx'
import React from 'react'
import { DatePicker, notification } from 'antd'
import {
  Image,
  Upload,
  Checkbox,
  Button,
  InputNumber,
  Dropdown,
  Input,
  Popconfirm,
  Select,
  Divider,
  Tooltip,
} from 'antd'
import CustomImage from '../components/CustomImage'
import { PasteList, FiltersParams, numberValues } from '../configs/sku'
import ArchiveIcon from '../components/ArchiveIcon/ArchiveIcon'
import styles from '../views/SKU/SKU.module.scss'
import UnarchiveIcon from '../components/UnarchiveIcon/ArchiveIcon'
import api from '../utils/axiosAuthInterceptor'
import _ from 'lodash'
import dayjs from 'dayjs'
import CustomPopConfirm from '../components/customPopConfirm/CustomPopConfirm'

import SelectFilter from '../components/tableFilters/SelectFilter'
import DatePickerFilter from '../components/tableFilters/DatePickerFilter'

import InputFilter from '../components/tableFilters/InputFilter'
import { FilterOutlined } from '@ant-design/icons'
import i18next from 'i18next'

const axios = api
const download = require('downloadjs')
const moment = require('moment')
const { validateImage } = require('image-validator')
const msInHour = 3600000

class SKUstore {
  isCopy = false
  timeout = null
  isArchive = false
  brands = []
  exportLoading = false
  intCodes = []
  subIntCodes = []
  categories = null
  accessories = null
  new_accessories = null
  nodes = []
  selectedRows = []
  customerModels = []
  customerModelsNames = []
  newModelArticle = ''
  lastNewModelArticle = ''
  newModelIndex = null
  newModel = ''
  addModelModalOpened = false
  saveModelButtonLoading = false
  saveModelButtonDisabled = true
  factories = []
  selectedAllColumns = true
  selectedAllColumnsInterminate = false
  i18n = null

  pasteList = PasteList

  productGroups = []
  photoPreviewImage = ''
  photoPreviewTitle = ''
  photoPreviewVisible = false

  isEdit = false
  isLoading = false
  nodesInputLoading = false

  productsList = []
  loadedAll = false
  perLoad = 30
  selectedProductGroup = undefined
  currentProductGroup = undefined

  uniqueSupplierModelsQty = null
  uniqueCustomerModelsQty = null

  filtersParams = FiltersParams

  isColumnsSelectorOpened = false
  selectedColumns = []

  queriesList = []

  exportToExcelType = 1
  exportModalOpen = false

  filterOpen = false

  //Handle errors
  handleErrors = (error) => {
    const message = error.response?.data?.message || error.message
    this.openNotificationWithIcon('error', i18next.t(message))
  }

  //Modals
  NewAccessoriesModalOpen = false
  NewAccessoriesModalLoading = false

  addNewAccessories = async (name) => {
    try {
      this.NewAccessoriesModalLoading = true
      const response = await axios.post('/api/node/v1/sku/newAccessories', {
        name,
      })
      this.new_accessories = [...response.data.data]
      this.openNotificationWithIcon('success', i18next.t('w_saved'))
    } catch (error) {
      this.handleErrors(error)
    } finally {
      this.NewAccessoriesModalLoading = false
      this.NewAccessoriesModalOpen = false
    }
  }

  get columns() {
    return [
      {
        title: () => this.t('w_action'),
        dataIndex: 'action',
        nameKey: 'w_action',
        fixed: 'left',
        width: 200,
        render: (value, row, index) =>
          !row.child ? (
            <div>
              {row.isEdit ? (
                <div className={styles.actionColumn}>
                  <Button type="primary" onClick={() => this.save(index)}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </Button>
                  <Button
                    onClick={() => {
                      this.isCopy = false
                      this.isEdit = false
                      if (!row.id) {
                        this.deleteSKU(row, index)
                        return
                      }
                      this.getOneProduct(index)
                    }}
                    danger
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </Button>
                </div>
              ) : !this.isArchive ? (
                <div className={styles.actionColumn}>
                  {(new Date() - new Date(row.created_at)) / msInHour < 24 && (
                    <CustomPopConfirm
                      cancelText={this.t('w_cancel')}
                      title={this.t('w_are_you_sure')}
                      onConfirm={() => {
                        if (!row.hasParent) {
                          this.deleteSKU(row, index)
                        }
                      }}
                      htmlBody={
                        <Button disabled={row.hasParent}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </Button>
                      }
                    />
                  )}
                  <Tooltip title={i18next.t('w_edit')}>
                    <Button
                      disabled={this.isEdit ?? this.isEdit !== index}
                      onClick={() => {
                        this.editRow(index)
                      }}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>
                    </Button>
                  </Tooltip>

                  <Tooltip title={i18next.t('w_copy')}>
                    <Button
                      disabled={this.isEdit ?? this.isEdit !== index}
                      onClick={() => this.copyRow(index)}
                    >
                      <i className="fa fa-clone" aria-hidden="true"></i>
                    </Button>
                  </Tooltip>

                  <Tooltip title={i18next.t('w_archive_goods')}>
                    <Button
                      disabled={this.isEdit ?? this.isEdit !== index}
                      onClick={() => this.archiveRow(index)}
                    >
                      <ArchiveIcon />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <div className={styles.actionColumn}>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => this.unarchiveRow(index)}
                    getPopupContainer={() =>
                      document.getElementById('sku_container')
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>
                      <UnarchiveIcon />
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </div>
          ) : (
            <Popconfirm
              title="Are you sure to delete this node?"
              onConfirm={() => this.removeChild(row.parentId, row.childId)}
            >
              <Button type="link">
                <i
                  className={`${styles.acceptDeleteIcon} fa fa-trash`}
                  aria-hidden="true"
                ></i>
              </Button>
            </Popconfirm>
          ),
      },
      {
        title: () => (
          <Tooltip title={this.t('w_recommendations')}>
            {this.t('w_recommendations')}
          </Tooltip>
        ),
        dataIndex: 'sample_test_sku',
        ellipsis: {
          showTitle: false,
        },
        width: 100,
        nameKey: 'w_recommendations',
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.sample_test_sku.input}
            options={this.filtersParams.sample_test_sku.options}
            onFilter={(value) => {
              this.changeFilter(value, 'sample_test_sku')
              this.acceptFilter('sample_test_sku')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'sample_test_sku')
              this.acceptFilter('sample_test_sku')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.sample_test_sku.input.length !== 0
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (row.isEdit && !value) {
            return (
              <div className={styles.recommendationsCheckbox}>
                <Checkbox
                  onChange={({ target }) =>
                    this.onChangeValue(
                      target.checked,
                      index,
                      'recommended_manually'
                    )
                  }
                  defaultChecked={row.recommended_manually}
                />
              </div>
            )
          }

          if (value) {
            return (
              <Tooltip
                title={
                  <div className={styles.recommendationsTooltip}>
                    <div>
                      <div className={styles.recommendationsTooltip__title}>
                        {this.t('w_user')}:
                      </div>
                      <div className={styles.recommendationsTooltip__value}>
                        {value.user}
                      </div>
                    </div>
                    <div>
                      <div className={styles.recommendationsTooltip__title}>
                        {this.t('w_date')}:
                      </div>
                      <div className={styles.recommendationsTooltip__value}>
                        {value.date}
                      </div>
                    </div>
                    <div>
                      <div className={styles.recommendationsTooltip__title}>
                        {this.t('w_sample_test')}:
                      </div>
                      <div className={styles.recommendationsTooltip__value}>
                        <a
                          target="_blank"
                          href={`/react/sampleTest/${value.sample_test_id}`}
                        >
                          Inquiry V-{value.sample_test_id}
                        </a>
                      </div>
                    </div>
                  </div>
                }
              >
                <div
                  className={
                    value.status ? styles.status_green : styles.status_red
                  }
                ></div>
              </Tooltip>
            )
          } else if (row.recommended_manually) {
            return (
              <Tooltip
                title={
                  <div className={styles.recommendationsTooltip}>
                    <div>
                      <div className={styles.recommendationsTooltip__title}>
                        {this.t('w_user')}:
                      </div>
                      <div className={styles.recommendationsTooltip__value}>
                        {row.creator}
                      </div>
                    </div>
                    <div>
                      <div className={styles.recommendationsTooltip__title}>
                        {this.t('w_date')}:
                      </div>
                      <div className={styles.recommendationsTooltip__value}>
                        {row.created_at}
                      </div>
                    </div>
                    <div className={styles.recommendationsTooltip__manually}>
                      {this.t('w_filled_in_manually')}
                    </div>
                  </div>
                }
              >
                <div className={styles.status_green}></div>
              </Tooltip>
            )
          }

          return ''
        },
      },
      {
        title: () => (
          <div className={styles.confirnationDate}>
            {this.t('w_date_of_confirmation')}
            <Tooltip title={this.t('w_date_of_latest_tt')}>
              <div className={styles.confirnationDateTooltip}>
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              </div>
            </Tooltip>
          </div>
        ),
        width: 170,
        filterDropdown: ({ confirm }) => {
          return (
            <DatePickerFilter
              selectedData={
                this.filtersParams.date_of_confirmation.input.length
                  ? this.filtersParams.date_of_confirmation.input
                  : null
              }
              onFilter={(value) => {
                this.changeFilter(value, 'date_of_confirmation')
                this.acceptFilter('date_of_confirmation')
                confirm()
              }}
              onReset={() => {
                this.changeFilter([], 'date_of_confirmation')
                this.acceptFilter('date_of_confirmation')
                confirm()
                this.resetLoaded()
              }}
              forceCleanup={!this.filterOpen}
            />
          )
        },
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.date_of_confirmation.input.length !== 0
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        nameKey: 'w_date_of_confirmation',
        dataIndex: 'date_of_confirmation',
        render: (value, row, index) => {
          if (!row.isEdit) {
            return value
          }
          return (
            <div>
              <DatePicker
                onChange={(e) =>
                  this.onChangeValue(e, index, 'date_of_confirmation')
                }
                value={value ? dayjs(value) : ''}
              />
            </div>
          )
        },
      },
      {
        title: () => (
          <Tooltip title={this.t('w_Category_A_int_code')}>
            {this.t('w_Category_A_int_code')}
          </Tooltip>
        ),
        dataIndex: 'categoryA',
        ellipsis: {
          showTitle: false,
        },
        width: 120,
        nameKey: 'w_Category_A_int_code',
        children: this.intCodes,
      },
      {
        title: () => (
          <Tooltip title={this.t('w_node')}>
            <div>{this.t('w_node')}</div>
          </Tooltip>
        ),
        ellipsis: {
          showTitle: false,
        },
        dataIndex: 'node',
        nameKey: 'w_node',
        width: 75,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={
              this.filtersParams.node.input
                ? [this.filtersParams.node.input]
                : []
            }
            single={true}
            options={[
              { value: '1', label: 'Yes' },
              { value: '0', label: 'No' },
            ]}
            onFilter={(value) => {
              this.changeFilter(value, 'node')
              this.acceptFilter('node')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'node')
              this.acceptFilter('node')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.node.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (!row.isEdit) {
            return value ? 'Yes' : 'No'
          }

          return (
            <div className={styles.nodeColumn}>
              {!row.children && (
                <Checkbox
                  onChange={({ target }) =>
                    this.onChangeValue(target.checked, index, 'node')
                  }
                  defaultChecked={value}
                  disabled={!row.isEdit || row.hasParent}
                />
              )}
              {row.isEdit && !value && (
                <Select
                  loading={row.nodesLoading}
                  mode="multiple"
                  onSearch={(e) => this.findNodes(e, index)}
                  filterOption={false}
                  showSearch
                  notFoundContent={null}
                  placeholder={'Type code...'}
                  onChange={(value) => this.changeNodes(value, index)}
                  className={styles.columnSelect}
                >
                  {row.nodesList.map((el) => (
                    <Select.Option value={el.id}>
                      {el.supplierModel} - {this.t(el.name)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          )
        },
      },
      {
        title: () => this.t('w_category_a'),
        nameKey: 'w_Category_A',
        dataIndex: 'category1',
        width: 130,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.category1.input}
            options={this.filtersParams.category1.options}
            onFilter={(value) => {
              this.changeFilter(value, 'category1')
              this.acceptFilter('category1')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'category1')
              this.acceptFilter('category1')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.category1.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value) => this.t(value),
      },
      {
        title: () => this.t('w_category_b'),
        dataIndex: 'category2',
        nameKey: 'w_Category_B',
        width: 130,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.category2.input}
            options={this.filtersParams.category2.options}
            onFilter={(value) => {
              this.changeFilter(value, 'category2')
              this.acceptFilter('category2')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'category2')
              this.acceptFilter('category2')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.category2.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value) => this.t(value),
      },
      {
        title: () => (
          <Tooltip title={this.t('w_Product_groups_int_code')}>
            {this.t('w_Product_groups_int_code')}
          </Tooltip>
        ),
        nameKey: 'w_Product_groups_int_code',
        ellipsis: {
          showTitle: false,
        },
        width: 120,
        dataIndex: 'productGroupsIntCode',
        children: this.subIntCodes,
      },
      {
        title: () => <div>{this.t('w_product_groups')}</div>,
        dataIndex: 'productGroupId',
        nameKey: 'w_product_groups',
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.productGroupId.input}
            options={this.filtersParams.productGroupId.options}
            onFilter={(value) => {
              this.changeFilter(value, 'productGroupId')
              this.acceptFilter('productGroupId')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'productGroupId')
              this.acceptFilter('productGroupId')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.productGroupId.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        width: 200,
        render: (value, row, index) => {
          if (!row.isEdit) {
            if (!this.categories) {
              return ''
            }
            return this.t(
              this.categories.find((category) => category.id === value)
                ?.value ?? ''
            )
          }

          return (
            <Select
              optionFilterProp="children"
              showSearch
              disabled={!row.isEdit}
              onChange={async (e) => {
                const productGroupData = await this.getProductGroupData(e)
                this.productsList[index] = {
                  ...this.productsList[index],
                  ...productGroupData,
                }
              }}
              value={value}
              className={styles.columnSelect}
            >
              {this.categories &&
                this.categories.map((el, index) => (
                  <Select.Option
                    key={index}
                    value={el.id}
                    disabled={!!el.deleted_at}
                  >
                    {this.t(el.value)}
                  </Select.Option>
                ))}
            </Select>
          )
        },
      },
      {
        title: () => <div>{this.t('w_Goods_name_for_BL')}</div>,
        dataIndex: 'goodsNameForBL',
        nameKey: 'w_Goods_name_for_BL',
        width: 130,
        filterDropdown: ({ confirm }) => (
          <InputFilter
            placeholder={this.t('w_Goods_name_for_BL')}
            selectedData={this.filtersParams.goodsNameForBL.input || ''}
            onFilter={(value) => {
              this.changeFilter(value, 'goodsNameForBL')
              this.acceptFilter('goodsNameForBL')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'goodsNameForBL')
              this.acceptFilter('goodsNameForBL')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.goodsNameForBL.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) =>
          this.renderValue(value, index, 'goodsNameForBL', row),
      },
      {
        title: () => <div>{this.t('w_HS_code')}</div>,
        dataIndex: 'HS_code',
        nameKey: 'w_HS_code',
        width: 120,
        filterDropdown: ({ confirm }) => (
          <InputFilter
            placeholder={this.t('w_HS_code')}
            selectedData={this.filtersParams.HS_code.input || ''}
            onFilter={(value) => {
              this.changeFilter(value, 'HS_code')
              this.acceptFilter('HS_code')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'HS_code')
              this.acceptFilter('HS_code')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.HS_code.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (row.isEdit && !row.HSCodeByArticle)
            return (
              <InputNumber
                className="tableValueInput"
                defaultValue={value}
                onBlur={(_) => this.checkHSCode(index)}
                onChange={(e) => this.onChangeValue(e, index, 'HS_code')}
              />
            )
          return value
        },
      },
      {
        title: () => <div>{this.t('w_Supplier_code')}</div>,
        dataIndex: 'supplierCode',
        nameKey: 'w_Supplier_code',
        width: 110,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.supplierCode.input}
            options={this.filtersParams.supplierCode.options}
            onFilter={(value) => {
              this.changeFilter(value, 'supplierCode')
              this.acceptFilter('supplierCode')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'supplierCode')
              this.acceptFilter('supplierCode')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.supplierCode.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, idx) => {
          if (!row.isEdit) {
            return this.factories.find((el) => el.value === value)?.label ?? ''
          }

          return (
            <Select
              optionFilterProp="children"
              onChange={(e) => this.onChangeValue(e, idx, 'supplierCode')}
              showSearch
              defaultValue={value}
              disabled={!row.isEdit}
              allowClear
              className={styles.columnSelect}
            >
              {this.factories.map((el, index) => (
                <Select.Option key={index} value={el.value}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          )
        },
      },
      {
        title: () => <div>{this.t('w_supplier_model')}</div>,
        width: 130,
        filterDropdown: ({ confirm }) => (
          <InputFilter
            placeholder={this.t('w_supplier_model')}
            selectedData={this.filtersParams.supplierModel.input || ''}
            onFilter={(value) => {
              this.changeFilter(value, 'supplierModel')
              this.acceptFilter('supplierModel')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'supplierModel')
              this.acceptFilter('supplierModel')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.supplierModel.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        nameKey: 'w_Supplier_model',
        dataIndex: 'supplierModel',
        render: (value, row, index) =>
          this.renderValue(value, index, 'supplierModel', row),
      },
      {
        title: () => <div>{this.t('w_new_accessories')}</div>,
        nameKey: 'w_new_accessories',
        width: 110,
        dataIndex: 'sku_new_accessories',
        //NOTE: don't delete
        // filterDropdown: ({ confirm }) => {
        //   return (
        //     <SelectFilter
        //       selectedData={this.filtersParams.new_accessories.input}
        //       options={this.filtersParams.new_accessories.options}
        //       onFilter={(value) => {
        //         this.changeFilter(value, 'new_accessories')
        //         this.acceptFilter('new_accessories')
        //         confirm()
        //       }}
        //       onReset={() => {
        //         this.changeFilter([], 'new_accessories')
        //         this.acceptFilter('new_accessories')
        //         confirm()
        //         this.resetLoaded()
        //       }}
        //       forceCleanup={!this.filterOpen}
        //     />
        //   )
        // },
        // filterIcon: () => (
        //   <FilterOutlined
        //     className={`${styles.filterIcon} ${
        //       this.filtersParams.new_accessories.input.length
        //         ? styles.filtered
        //         : ''
        //     }`}
        //   />
        // ),
        // onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, idx) => {
          return (
            <Select
              optionFilterProp="children"
              onChange={(e) => this.onChangeValue(e, idx, 'new_accessories')}
              showSearch
              defaultValue={value?.map((el) => el?.new_accessory?.id)}
              mode="multiple"
              disabled={!row.isEdit}
              allowClear
              className={styles.columnSelect}
              popupMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider className={styles.specDropdown__divider} />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.NewAccessoriesModalOpen = true
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault()
                      }}
                    >
                      {i18next.t('w_add')}
                    </Button>
                  </div>
                </div>
              )}
            >
              {this.new_accessories?.map((el) => {
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {i18next.t(el.name)}
                  </Select.Option>
                )
              })}
            </Select>
          )
        },
      },
      {
        title: () => <div>{this.t('w_model_introduction_date')}</div>,
        nameKey: 'w_model_introduction_date',
        dataIndex: 'modification_date',
        width: 140,
        filterDropdown: ({ confirm }) => (
          <DatePickerFilter
            selectedData={this.filtersParams.modification_date.input}
            onFilter={(value) => {
              this.changeFilter(value, 'modification_date')
              this.acceptFilter('modification_date')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'modification_date')
              this.acceptFilter('modification_date')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.modification_date.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (!row.isEdit || row.model_intro_date_editable === false) {
            return moment(value).format('YYYY-MM-DD')
          }
          return (
            <DatePicker
              value={dayjs(value)}
              allowClear={false}
              onChange={(e) =>
                this.onChangeValue(e, index, 'modification_date')
              }
            />
          )
        },
      },
      {
        title: () => <div>{this.t('w_SKU_int_code')}</div>,
        dataIndex: 'article',
        nameKey: 'w_SKU_int_code',
        width: 120,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.article.input}
            options={this.filtersParams.article.options}
            onFilter={(value) => {
              this.changeFilter(value, 'article')
              this.acceptFilter('article')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'article')
              this.acceptFilter('article')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.article.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          return row.isEdit ? (
            <Select
              onSearch={(e) => this.findModels(e, index)}
              filterOption={false}
              showSearch
              allowClear
              searchValue={row.articleSearch}
              onDropdownVisibleChange={() => {
                this.clearNewModel()
                this.findModels(value, index)
              }}
              placeholder={'Type code...'}
              onChange={(value, option) =>
                this.changeModel(value, index, option)
              }
              value={value}
              className={styles.articleColumn}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider className={styles.articleColumn__divider} />
                  <Button
                    type="link"
                    onClick={() => this.openModal(index)}
                    className={styles.articleColumn__button}
                  >
                    {this.t('w_add')}
                  </Button>
                </>
              )}
            >
              {row.modelsList.map((el, index) => (
                <Select.Option
                  key={index}
                  goodsName={el.goods_name}
                  HS_code={el.HS_code}
                  brand_id={el.brand_id}
                  name={el.name}
                  value={el.customerModelId}
                  productGroup={el.productGroup}
                  customerModelCreatedAt={el.customerModelCreatedAt}
                >
                  {el.article}
                </Select.Option>
              ))}
            </Select>
          ) : value ? (
            value
          ) : row.sample_test_sku?.status ? (
            <div>
              <Tooltip title={this.t('w_sku_waiting_for_intcode')}>
                <div className={styles.waitingForIntcode}>INT CODE</div>
              </Tooltip>
            </div>
          ) : (
            ''
          )
        },
      },
      {
        title: () => <div>{this.t('w_Customer_model')}</div>,
        dataIndex: 'customerModel',
        nameKey: 'w_Customer_model',
        width: 100,
        filterDropdown: ({ confirm }) => (
          <InputFilter
            placeholder={this.t('w_Customer_model')}
            selectedData={this.filtersParams.customerModel.input || ''}
            onFilter={(value) => {
              this.changeFilter(value, 'customerModel')
              this.acceptFilter('customerModel')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'customerModel')
              this.acceptFilter('customerModel')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              !!this.filtersParams.customerModel.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) =>
          this.renderValue(value, index, 'customerModel', row),
      },
      {
        title: () => <div>{this.t('w_Brand')}</div>,
        dataIndex: 'brand',
        nameKey: 'w_Brand',
        width: 100,
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={this.filtersParams.brand.input}
            options={this.filtersParams.brand.options}
            onFilter={(value) => {
              this.changeFilter(value, 'brand')
              this.acceptFilter('brand')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'brand')
              this.acceptFilter('brand')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.brand.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, idx) => {
          if (!row.isEdit) {
            if (!this.brands) {
              return ''
            }
            return this.brands.find((el) => el.id === value)?.name ?? ''
          }
          return (
            <Select
              optionFilterProp="children"
              onChange={(e) => this.onChangeValue(e, idx, 'brand')}
              showSearch
              defaultValue={value}
              allowClear
              className={styles.columnSelect}
            >
              {this.brands.map((el, index) => (
                <Select.Option key={index} value={el.id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          )
        },
      },
      {
        title: () => <div>{this.t('w_contract_number')}</div>,
        dataIndex: 'contracts',
        nameKey: 'w_contract_number',
        width: 100,
        render: (value) => (
          <div>
            {!!value?.length &&
              value.map((contract) => (
                <div>
                  <a
                    target="_blank"
                    href={`/react/contracts/data?&id=${contract.id}&tab=specification`}
                  >
                    {contract.name}
                  </a>
                </div>
              ))}
          </div>
        ),
      },
      {
        title: () => <div>{this.t('w_accessories')}</div>,
        nameKey: 'w_Accessories',
        width: 110,
        dataIndex: 'accessories',
        filterDropdown: ({ confirm }) => {
          return (
            <SelectFilter
              selectedData={this.filtersParams.accessories.input}
              options={this.filtersParams.accessories.options[i18next.language]}
              onFilter={(value) => {
                this.changeFilter(value, 'accessories')
                this.acceptFilter('accessories')
                confirm()
              }}
              onReset={() => {
                this.changeFilter([], 'accessories')
                this.acceptFilter('accessories')
                confirm()
                this.resetLoaded()
              }}
              forceCleanup={!this.filterOpen}
            />
          )
        },
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.accessories.input.length ? styles.filtered : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, idx) => {
          return (
            <Select
              optionFilterProp="children"
              onChange={(e) => this.onChangeValue(e, idx, 'accessories')}
              showSearch
              defaultValue={value}
              mode="multiple"
              disabled={!row.isEdit}
              allowClear
              className={styles.columnSelect}
              popupMatchSelectWidth={false}
            >
              {this.accessories &&
                this.accessories.map((el, index) => {
                  return (
                    <Select.Option key={index} value={el.id}>
                      {el[`name_${i18next.language}`]}
                    </Select.Option>
                  )
                })}
            </Select>
          )
        },
      },
      {
        title: () => <div>{this.t('w_Contains_battery')}</div>,
        width: 100,
        nameKey: 'w_Contains_battery',
        dataIndex: 'containsBattery',
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={
              this.filtersParams.containsBattery.input
                ? [this.filtersParams.containsBattery.input]
                : []
            }
            single={true}
            options={[
              { value: '1', label: 'Yes' },
              { value: '0', label: 'No' },
            ]}
            onFilter={(value) => {
              this.changeFilter(value, 'containsBattery')
              this.acceptFilter('containsBattery')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'containsBattery')
              this.acceptFilter('containsBattery')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.containsBattery.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (row.article === 'add')
            return {
              children: '',
              props: { colSpan: 0 },
            }
          return (
            <Checkbox
              disabled={!row.isEdit}
              defaultChecked={value}
              onChange={(e) =>
                this.onChangeValue(e.target.checked, index, 'containsBattery')
              }
            />
          )
        },
      },
      {
        title: () => <div>{this.t('w_Other_special_shipment_condition')}</div>,
        width: 100,
        nameKey: 'w_Other_special_shipment_condition',
        dataIndex: 'shipmentCondition',
        filterDropdown: ({ confirm }) => (
          <SelectFilter
            selectedData={
              this.filtersParams.shipmentCondition.input
                ? [this.filtersParams.shipmentCondition.input]
                : []
            }
            single={true}
            options={[
              { value: '1', label: 'Yes' },
              { value: '0', label: 'No' },
            ]}
            onFilter={(value) => {
              this.changeFilter(value, 'shipmentCondition')
              this.acceptFilter('shipmentCondition')
              confirm()
            }}
            onReset={() => {
              this.changeFilter([], 'shipmentCondition')
              this.acceptFilter('shipmentCondition')
              confirm()
              this.resetLoaded()
            }}
            forceCleanup={!this.filterOpen}
          />
        ),
        filterIcon: () => (
          <FilterOutlined
            className={`${styles.filterIcon} ${
              this.filtersParams.shipmentCondition.input.length
                ? styles.filtered
                : ''
            }`}
          />
        ),
        onFilterDropdownOpenChange: (visible) => this.setFilterOpen(visible),
        render: (value, row, index) => {
          if (row.article === 'add')
            return {
              children: '',
              props: { colSpan: 0 },
            }
          return (
            <Checkbox
              disabled={!row.isEdit}
              defaultChecked={value}
              onChange={(e) =>
                this.onChangeValue(e.target.checked, index, 'shipmentCondition')
              }
            />
          )
        },
      },
      {
        title: () => <div>{this.t('w_Standard_inspection_time_days')}</div>,
        width: 100,
        nameKey: 'w_Standard_inspection_time_days',
        dataIndex: 'inspectionTime',
        render: (value, row, index) => {
          return this.renderValue(value, index, 'inspectionTime', row)
        },
      },
      {
        title: () => <div>{this.t('w_Quantity_in_transport_box_pcs')}</div>,
        width: 100,
        nameKey: 'w_Quantity_in_transport_box_pcs',
        dataIndex: 'qtyInTransportBox',
        render: (value, row, index) =>
          this.renderValue(value, index, 'qtyInTransportBox', row),
      },
      {
        title: () => this.t('w_Transport_box_package_size'),
        nameKey: 'w_Transport_box_package_size',
        dataIndex: 'transportBoxPackageSize',
        width: 150,
        children: [
          {
            title: () => (
              <Tooltip title={this.t('w_Width_А_mm')}>
                <div>{this.t('w_Width_А_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'width',
            render: (value, row, index) =>
              this.renderValue(value, index, 'width', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Height_B_mm')}>
                <div>{this.t('w_Height_B_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'height',
            render: (value, row, index) =>
              this.renderValue(value, index, 'height', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Depth_C_mm')}>
                <div> {this.t('w_Depth_C_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'depth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'depth', row),
          },
        ],
      },
      {
        title: () => (
          <Tooltip title={this.t('w_GW_kg')}>
            <div> {this.t('w_GW_kg')}</div>
          </Tooltip>
        ),
        width: 50,
        ellipsis: {
          showTitle: false,
        },
        nameKey: 'w_GW_kg',
        dataIndex: 'GW',
        render: (value, row, index) =>
          this.renderValue(value, index, 'GW', row),
      },
      {
        title: () => (
          <Tooltip title={this.t('w_Quantity_in_Intermediate_box_pcs')}>
            <div> {this.t('w_Quantity_in_Intermediate_box_pcs')}</div>
          </Tooltip>
        ),
        width: 50,
        ellipsis: {
          showTitle: false,
        },
        nameKey: 'w_Quantity_in_Intermediate_box_pcs',
        dataIndex: 'qtyInIntermediateBox',
        render: (value, row, index) =>
          this.renderValue(value, index, 'qtyInIntermediateBox', row),
      },
      {
        title: () => this.t('w_Intermediate_package_size_mm'),
        nameKey: 'w_Intermediate_package_size_mm',
        width: 150,
        dataIndex: 'intermediatePackageSize',
        children: [
          {
            title: () => (
              <Tooltip title={this.t('w_Width_А_mm')}>
                <div> {this.t('w_Width_А_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'intermediateWidth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'intermediateWidth', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Height_B_mm')}>
                <div> {this.t('w_Height_B_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'intermediateHeight',
            render: (value, row, index) =>
              this.renderValue(value, index, 'intermediateHeight', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Depth_C_mm')}>
                <div> {this.t('w_Depth_C_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'intermediateDepth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'intermediateDepth', row),
          },
        ],
      },
      {
        title: () => (
          <Tooltip title={this.t('w_GW_intermediate')}>
            <div> {this.t('w_GW_intermediate')}</div>
          </Tooltip>
        ),
        width: 50,
        ellipsis: {
          showTitle: false,
        },
        nameKey: 'w_GW_intermediate',
        dataIndex: 'intermediateGW',
        render: (value, row, index) =>
          this.renderValue(value, index, 'intermediateGW', row),
      },
      {
        title: () => this.t('w_Individual_packing_size_mm'),
        nameKey: 'w_Individual_packing_size_mm',
        dataIndex: 'individualPackingSize',
        width: 150,
        children: [
          {
            title: () => (
              <Tooltip title={this.t('w_Width_А_mm')}>
                <div> {this.t('w_Width_А_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'individualWidth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'individualWidth', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Height_B_mm')}>
                <div> {this.t('w_Height_B_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'individualHeight',
            render: (value, row, index) =>
              this.renderValue(value, index, 'individualHeight', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Depth_C_mm')}>
                <div> {this.t('w_Depth_C_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'individualDepth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'individualDepth', row),
          },
        ],
      },
      {
        title: () => (
          <Tooltip title={this.t('w_GW_kg_Example')}>
            <div> {this.t('w_GW_kg_Example')}</div>
          </Tooltip>
        ),
        width: 50,
        ellipsis: {
          showTitle: false,
        },
        nameKey: 'w_GW_kg_Example',
        dataIndex: 'individualGW',
        render: (value, row, index) =>
          this.renderValue(value, index, 'individualGW', row),
      },
      {
        title: () => this.t('w_Assembled_product_size_mm'),
        nameKey: 'w_Assembled_product_size_mm',
        width: 150,
        dataIndex: 'assembledProduct',
        children: [
          {
            title: () => (
              <Tooltip title={this.t('w_Width_А_mm')}>
                <div> {this.t('w_Width_А_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'assembledWidth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'assembledWidth', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Height_B_mm')}>
                <div> {this.t('w_Height_B_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'assembledHeight',
            render: (value, row, index) =>
              this.renderValue(value, index, 'assembledHeight', row),
          },
          {
            title: () => (
              <Tooltip title={this.t('w_Depth_C_mm')}>
                <div> {this.t('w_Depth_C_mm')}</div>
              </Tooltip>
            ),
            width: 50,
            ellipsis: {
              showTitle: false,
            },
            dataIndex: 'assembledDepth',
            render: (value, row, index) =>
              this.renderValue(value, index, 'assembledDepth', row),
          },
        ],
      },
      {
        title: () => this.t('w_NW_kg_Example'),
        nameKey: 'w_NW_kg_Example',
        width: 100,
        dataIndex: 'NW',
        render: (value, row, index) =>
          this.renderValue(value, index, 'NW', row),
      },
      {
        title: () => this.t('w_photo'),
        width: 700,
        nameKey: 'w_photo',
        dataIndex: 'photos',
        render: (value, row, index) => {
          if (row.article === 'add')
            return {
              children: '',
              props: { colSpan: 0 },
            }
          return (
            <div className={styles.photoColumn}>
              {row.isEdit ? (
                <div>
                  <div className={styles.photoColumnEdit}>
                    {value.map((el, photoIdx) => (
                      <CustomImage
                        key={photoIdx}
                        canDelete={true}
                        onDelete={() => this.deleteOldPhotos(index, photoIdx)}
                        src={el.filename}
                      />
                    ))}
                  </div>
                  <Upload
                    beforeUpload={(file) => {
                      this.changePhotos(index, file, 'add')
                      return false
                    }}
                    onRemove={(file) => {
                      this.changePhotos(index, file, 'remove')
                    }}
                    filelist={row.newPhotos}
                    defaultFileList={row.newPhotos}
                    listType="picture-card"
                    multiple
                    onPreview={this.handlePreview}
                  >
                    + Upload
                  </Upload>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '5px',
                  }}
                >
                  <Image.PreviewGroup>
                    {value.map((el, elId) => (
                      <Image key={elId} width={100} src={el.filename} />
                    ))}
                  </Image.PreviewGroup>
                </div>
              )}
            </div>
          )
        },
      },
      {
        title: () => this.t('w_ed_bom'),
        width: 250,
        nameKey: 'w_ed_bom',
        dataIndex: 'files',
        render: (value, row, index) => {
          if (row.article === 'add')
            return {
              children: '',
              props: { colSpan: 0 },
            }
          return (
            <div className={styles.filesColumn}>
              <Upload
                beforeUpload={(file) => {
                  this.addFile(index, file)
                  return false
                }}
                onPreview={async (file) => {
                  const response = await axios.get(file.url, {
                    responseType: 'blob',
                  })
                  download(response.data, file.name)
                }}
                showUploadList={{
                  showRemoveIcon: !!row.isEdit,
                  removeIcon: (file) => (
                    <CustomPopConfirm
                      cancelText={this.t('w_cancel')}
                      title={this.t('w_are_you_sure')}
                      onConfirm={() => this.removeFile(index, file)}
                      htmlBody={
                        <i
                          style={{ paddingLeft: '5px', cursor: 'pointer' }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      }
                    />
                  ),
                }}
                fileList={value}
                multiple
              >
                {row.isEdit && <Button>Upload</Button>}
              </Upload>
            </div>
          )
        },
      },
    ]
  }

  //Переменная для редактирования
  tmpRow = {}

  constructor() {
    makeAutoObservable(this)
  }

  t = (value) => {
    return value
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
      description: description,
    })
  }

  setFilterOpen = (visible) => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(
      () => {
        this.filterOpen = visible
      },
      !visible ? 300 : 0
    )
  }

  changePhotos = async (index, file, type) => {
    let temp = [...this.productsList]
    if (type === 'add') {
      if (!temp[index].newPhotos) {
        temp[index].newPhotos = []
      }
      temp[index].newPhotos = [...temp[index].newPhotos, file]
    } else {
      temp[index].newPhotos.splice(
        temp[index].newPhotos.findIndex((el) => el.uid === file.uid),
        1
      )
    }
    this.productsList = [...temp]
  }

  addFile = async (index, file) => {
    let temp = [...this.productsList]
    temp[index].files.push({
      name: file.name,
      fileData: file,
    })
    this.productsList = [...temp]
  }

  removeFile = async (index, file) => {
    let temp = [...this.productsList]
    let fileIndex = temp[index].files.findIndex((el) => el === file)
    temp[index].files.splice(fileIndex, 1)
    this.productsList = [...temp]
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.photoPreviewImage = file.url || file.preview
    this.photoPreviewVisible = true
    this.photoPreviewTitle =
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
  }

  handleCancel = () => {
    this.photoPreviewVisible = false
  }

  changeSelectedGroup = (value) => {
    this.selectedProductGroup = value
  }

  //получение строк SKU
  findProducts = async (
    update = false,
    fromFilter = false,
    getFilters,
    archive = this.isArchive
  ) => {
    let perLoad = this.perLoad
    let length = this.productsList.length

    if (update) perLoad = this.productsList.length

    if (this.isArchive !== archive) {
      this.isArchive = archive
      this.productsList = []
    }

    if (fromFilter) this.loadedAll = false

    if (fromFilter || update) length = 0

    if (this.loadedAll && !update) return

    let data = {
      length,
      perLoad,
      filters: this.filtersParams,
      getCategories: this.categories ? false : true,
      getAccessories: this.accessories ? false : true,
      archive,
    }

    if (!update && !fromFilter) {
      if (this.queriesList.includes(length)) {
        return
      }
      this.queriesList.push(length)
    }

    try {
      this.isLoading = true
      if (getFilters) await this.getFiltersData()
      let response = await axios.post('/api/node/v1/sku/products/getAll', data)
      if (response.data.success) {
        let codes = []
        let subIntCodes = []
        for (let i = 1; i <= 2; i++) {
          //на будущее, если будет больше чем 2 инт кода
          codes.push({
            title: () => (
              <div>
                <Tooltip title={this.t('w_Category_1_int_code')}>
                  {this.t('w_Category_1_int_code')}
                </Tooltip>
              </div>
            ),
            filterDropdown: ({ confirm }) => (
              <SelectFilter
                selectedData={this.filtersParams[`intCode${i}`].input}
                options={this.filtersParams[`intCode${i}`].options}
                onFilter={(value) => {
                  this.changeFilter(value, `intCode${i}`)
                  this.acceptFilter(`intCode${i}`)
                  confirm()
                }}
                onReset={() => {
                  this.changeFilter([], `intCode${i}`)
                  this.acceptFilter(`intCode${i}`)
                  confirm()
                  this.resetLoaded()
                }}
                forceCleanup={!this.filterOpen}
              />
            ),
            filterIcon: () => (
              <FilterOutlined
                className={`${styles.filterIcon} ${
                  this.filtersParams[`intCode${i}`].input.length
                    ? styles.filtered
                    : ''
                }`}
              />
            ),
            onFilterDropdownOpenChange: (visible) =>
              this.setFilterOpen(visible),
            dataIndex: `intCode${i}`,
            ellipsis: {
              showTitle: false,
            },
            width: 70,
          })
          subIntCodes.push({
            title: () => (
              <Tooltip title={this.t('w_int')}>
                <div>{this.t('w_int')}</div>
              </Tooltip>
            ),
            dataIndex: `subIntCode${i}`,
            filterDropdown: ({ confirm }) => (
              <SelectFilter
                selectedData={this.filtersParams[`subIntCode${i}`].input}
                options={this.filtersParams[`subIntCode${i}`].options}
                onFilter={(value) => {
                  this.changeFilter(value, `subIntCode${i}`)
                  this.acceptFilter(`subIntCode${i}`)
                  confirm()
                }}
                onReset={() => {
                  this.changeFilter([], `subIntCode${i}`)
                  this.acceptFilter(`subIntCode${i}`)
                  confirm()
                  this.resetLoaded()
                }}
                forceCleanup={!this.filterOpen}
              />
            ),
            filterIcon: () => (
              <FilterOutlined
                className={`${styles.filterIcon} ${
                  this.filtersParams[`subIntCode${i}`].input.length
                    ? styles.filtered
                    : ''
                }`}
              />
            ),
            onFilterDropdownOpenChange: (visible) =>
              this.setFilterOpen(visible),
            ellipsis: {
              showTitle: false,
            },
            width: 70,
          })
        }

        this.intCodes = codes
        this.subIntCodes = subIntCodes

        let products = response.data.data.products

        if (products.length !== perLoad) this.loadedAll = true
        if (!update && !fromFilter) {
          if (products.length !== 0)
            if (!this.productsList.find((el) => el.id === products[0].id))
              this.productsList = [...this.productsList, ...products]
        } else this.productsList = products
      }
      if (!this.categories) {
        this.categories = response.data.data.categories
        this.filtersParams['productGroupId'].options =
          response.data.data.categories.map((category) => ({
            value: category.id,
            label: category.value,
          }))

        this.filtersParams['accessories'].options.ru =
          response.data.data.accessories.map((accessory) => ({
            value: accessory.id,
            label: accessory.name_ru,
          }))
        this.filtersParams['accessories'].options.en =
          response.data.data.accessories.map((accessory) => ({
            value: accessory.id,
            label: accessory.name_en,
          }))
        this.filtersParams['accessories'].options.cn =
          response.data.data.accessories.map((accessory) => ({
            value: accessory.id,
            label: accessory.name_cn,
          }))

        //NOTE: don't delete

        // this.filtersParams['new_accessories'].options =
        //   response.data.data.new_accessories.map((accessory) => ({
        //     value: accessory.id,
        //     label: accessory.name,
        //   }))
      }

      if (!this.accessories) {
        this.accessories = response.data.data.accessories
        this.new_accessories = response.data.data.new_accessories
      }
    } catch (error) {
      console.warn(error)
    } finally {
      this.isLoading = false
    }

    return
  }

  getUniqueQty = async () => {
    let response = await axios.post('/api/node/v1/sku/products/uniqueQty', {
      filters: this.filtersParams,
    })
    if (response.data.success) {
      this.uniqueSupplierModelsQty = response.data.data.supplierModelsQty
      this.uniqueCustomerModelsQty = response.data.data.customerModelsQty
    }
  }

  removeChild = async (parentId, childId) => {
    this.isLoading = true
    let data = {
      parentId,
      childId,
    }
    let response = await axios.put('/api/node/v1/sku/removeChild', data)
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Deleted!')
      this.selectedRows = []
      await this.findProducts(true)
    } else {
      this.openNotificationWithIcon('error', 'Error!')
      this.isLoading = false
    }
  }
  onChangeValue = (value, idx, param) => {
    if (numberValues.includes(param)) {
      value = value.replace(',', '.')
      if (isNaN(+value) || value === '') value = null
    }

    if (!this.selectedRows.includes(this.productsList[idx].id))
      this.productsList[idx][param] = value
    else
      for (let product of this.productsList) {
        if (!product.isEdit || !this.selectedRows.includes(product.id)) continue
        product[param] = value
      }
  }

  edit = (index) => {
    let temp = [...this.productsList]
    temp[index].isEdit = true
    this.productsList = [...temp]
  }

  deleteOldPhotos = async (index, photoIdx) => {
    let temp = [...this.productsList]
    temp[index].photos.splice(photoIdx, 1)
    this.productsList = [...temp]
  }

  save = async (index) => {
    let products = []
    this.isLoading = true

    try {
      if (!this.productsList[index].productGroupId)
        throw Error('No product group!')
      if (!this.productsList[index].supplierModel)
        throw Error('No supplier model!')
      if (!this.productsList[index].supplierCode)
        throw Error('No supplier code!')

      products = [this.productsList[index]]

      let response = await axios.post('/api/node/v1/sku/product/save', {
        products,
      })
      if (response.data.success) {
        let allUploaded = true
        for (let product of products) {
          if (!product.isEdit) continue

          let data = new FormData()
          let id = product.id ?? response.data.data

          this.productsList[index].id = id
          data.append('skuId', product.id)

          if (product.newPhotos) {
            for (let photo of product.newPhotos) {
              const isValidImage = await validateImage(photo)
              if (!isValidImage) {
                allUploaded = false
                continue
              }
              data.append('files', photo)
            }
          }

          if (product.isCopy) {
            const dataUrlToFile = async (dataUrl, fileName, type) => {
              const res = await fetch(dataUrl)
              const blob = await res.blob()
              return new File([blob], fileName, { type: type })
            }

            for (let photo of product.photos) {
              const filename =
                photo.filename.split('/')[photo.filename.split('/').length - 1]
              const file = await dataUrlToFile(
                photo.filename,
                filename,
                'image/' + photo.filename.split('.')[1]
              )
              data.append('files', file)
            }
          }
          if (product.photos && !product.isCopy)
            for (let photo of product.photos) {
              let lastIdx = photo.filename.lastIndexOf('/')
              data.append('oldPics[]', photo.filename.slice(lastIdx + 1))
            }
          await axios.post(`/api/node/v1/sku/upload/sku/${id}`, data)

          data = new FormData()
          data.append('id', id)

          if (product.files && !this.isCopy) {
            for (let file of product.files) {
              if (!Number.isInteger(file.uid) && file.fileData) {
                data.append('files', file.fileData)
              } else {
                data.append('oldFiles[]', file.url)
              }
            }
            await axios.put(`/api/node/v1/sku/uploadFiles`, data)
          }
        }
        if (allUploaded) this.openNotificationWithIcon('success', 'Saved!')
        else
          this.openNotificationWithIcon(
            'warning',
            'One or more photos were not added!'
          )

        this.getOneProduct(index)
      } else {
        throw response.data.message
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message ?? error)
      this.isLoading = false
    } finally {
      this.isEdit = false
      this.isCopy = false
    }
  }

  renderValue = (value, idx, param, row) => {
    if (!row.isEdit) {
      return value
    }
    return (
      <Input
        value={value}
        onPaste={(e) => this.pasteFromExcel(e, idx, param)}
        onChange={(e) => this.onChangeValue(e.target.value, idx, param)}
      />
    )
  }

  resetLoaded = () => {
    this.loadedAll = false
    this.queriesList = []
  }

  clearFiltersData = () => {
    this.resetLoaded()
    for (let el in this.filtersParams) {
      if (el === 'id') {
        this.filtersParams.id = null
        continue
      }
      this.filtersParams[el].filtered = FiltersParams[el].filtered
      this.filtersParams[el].input = FiltersParams[el].input
    }
  }

  clearFilters = async () => {
    this.clearFiltersData()
    this.findProducts(false, true)
    this.getUniqueQty()
  }

  cancel = async () => {
    this.isEdit = false
    await this.findProducts()
  }

  addRow = () => {
    //можно добавить только одну пустую строку,
    //иначе будут конфликты при выделении строк
    if (!this.productsList[0]?.id && this.productsList.length !== 0) return

    this.productsList.unshift({
      photos: [],
      productGroupId: null,
      isEdit: true,
      node: false,
      modification_date: new Date(),
      sku_nodes: [],
      nodesList: [],
      modelsList: [],
      files: [],
    })
  }

  copyRow = async (index) => {
    this.isCopy = true
    this.isEdit = `${index}`
    if (!this.productsList[0]?.id && this.productsList.length !== 0) return
    const copyRow = JSON.parse(
      JSON.stringify({
        ...this.productsList[index],
        new_accessories: this.productsList[index].sku_new_accessories.map(
          (el) => el.new_accessory.id
        ),
      })
    )
    copyRow.contracts = []
    this.productsList.unshift({
      ...copyRow,
      isEdit: true,
      id: undefined,
      nodes: this.productsList[index].sku_nodes.map((el) => el.node_id),
      isCopy: true,
      model_intro_date_editable: true,
      modification_date: new Date(),
    })
  }

  archiveRow = async (idx) => {
    const row = this.productsList[idx]
    this.isLoading = true
    let response = await axios.put(`/api/node/v1/sku/archive/${row.id}`)
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Archived!')
      this.productsList.splice(idx, 1)
    } else this.openNotificationWithIcon('error', response.data.message)
    this.isLoading = false
  }

  unarchiveRow = async (idx) => {
    const row = this.productsList[idx]
    this.isLoading = true
    let response = await axios.put(`/api/node/v1/sku/unarchive/${row.id}`)
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Archived!')
      this.productsList.splice(idx, 1)
    } else this.openNotificationWithIcon('error', response.data.message)
    this.isLoading = false
  }

  findDataByModel = async (idx) => {
    let product = this.productsList[idx]
    const model = product.supplierModel
    let data = {
      model: model,
    }
    this.isLoading = true
    let response = await axios.post(
      '/api/node/v1/sku/products/findByModel',
      data
    )
    let responseData = response.data.data
    if (responseData) {
      if (responseData.products) {
        product.article = responseData.products.vendor_code
        product.customerModel = responseData.products.name
      }
      if (responseData.factory)
        product.factory = responseData.factory.abbreviation
    }
    this.isLoading = false
  }

  renderOption = (field, el, trans) => {
    switch (field) {
      case 'supplierCode':
        return el.abbreviation
      case 'category1':
      case 'category2':
      case 'productGroupId':
        return this.t(el.value)
      default:
        return el.value
    }
  }

  closeFilter = (idx, clear = false) => {
    this.filtersParams[idx].opened = false
    if (clear)
      if (idx === 'node' || idx === 'supplierModel')
        this.filtersParams[idx].input = ''
      else this.filtersParams[idx].input = []

    let temp = [...this.productsList]
    this.productsList = [...temp]
  }

  //установка фильтров для поиска конкретной модели (например при переходе из модуля контрактов)
  setInitialFilters = (article, supplierModel, factoryId, id) => {
    if (supplierModel && factoryId && article && supplierModel.includes('__')) {
      let [supplierModelFilter, modelIntroDateFilter] =
        supplierModel.split('__')
      modelIntroDateFilter =
        modelIntroDateFilter.slice(0, 4) +
        '.' +
        modelIntroDateFilter.slice(4, 6) +
        '.' +
        modelIntroDateFilter.slice(6)
      this.filtersParams.supplierModel.input =
        this.filtersParams.supplierModel.filtered = supplierModelFilter
      this.filtersParams.modification_date.input =
        this.filtersParams.modification_date.filtered = [
          dayjs(modelIntroDateFilter).startOf('day'),
          dayjs(modelIntroDateFilter).endOf('day'),
        ]
      this.filtersParams.modification_date.filtered = [
        dayjs(modelIntroDateFilter).startOf('day'),
        dayjs(modelIntroDateFilter).endOf('day'),
      ]
      this.filtersParams.article.input = this.filtersParams.article.filtered = [
        +article,
      ]
      this.filtersParams.supplierCode.input =
        this.filtersParams.supplierCode.filtered = factoryId
    }
    if (id) {
      this.filtersParams.id = id
    }
  }

  changeFilter = (value, idx) => {
    this.filtersParams[idx].input = value
    let temp = [...this.productsList]
    this.productsList = [...temp]
  }

  acceptFilter = async (field) => {
    this.filtersParams[field].filtered = this.filtersParams[field].input
    this.closeFilter(field)
    this.findProducts(false, true)
    this.getUniqueQty()
  }

  getProductGroupData = async (value) => {
    let temp = {}
    let categoryData = await axios.get(
      `/api/node/v1/sku/products/getProductIdData/${value}`
    )
    if (categoryData.data.success) {
      temp.productGroupId = value
      Object.assign(temp, categoryData.data.data)
    }
    return temp
  }

  findNodes = async (value, index) => {
    if (value.length < 3) {
      return
    }
    let temp = [...this.productsList]

    let response = await axios.post(`/api/node/v1/sku/getNodes`, {
      input: value,
    })
    temp[index].nodesList = response.data.data
    this.productsList = [...temp]
  }

  findModels = async (value, index, type = 'article', setNewModel = false) => {
    let temp = [...this.productsList]

    if (type === 'article' && index !== null) {
      temp[index].articleSearch = value
    }

    if (value && value.length >= 3) {
      let response = await axios.post(`/api/node/v1/sku/getModels`, {
        input: value,
        type,
      })

      if (index !== null) temp[index].modelsList = response.data.data
      if (setNewModel) {
        this.productsList = [...temp]

        let modelObj = response.data.data.find(
          (model) => model.article === value
        )
        if (!modelObj) {
          return
        }

        modelObj.children = modelObj.article

        this.changeModel(modelObj.customerModelId, index, modelObj)
        return
      } else
        type === 'article'
          ? (this.customerModels = response.data.data)
          : (this.customerModelsNames = response.data.data)
    }

    this.productsList = [...temp]
  }

  changeNodes = (value, index) => {
    let temp = [...this.productsList]
    temp[index].nodes = value
    this.productsList = [...temp]
  }

  changeModel = async (value, index, option) => {
    let temp = [...this.productsList]

    if (!value) {
      temp[index].customerModelId = null
      temp[index].article = null
      temp[index].customerModel = null
      this.productsList = [...temp]
      return
    }

    temp[index].article = option.children
    temp[index].customerModelId = value
    temp[index].customerModel = option.name
    temp[index].customerModelCreatedAt = option.customerModelCreatedAt
    temp[index].HSCodeByArticle = false
    temp[index].goodsNameByArticle = false
    temp[index].brandByArticle = false

    if (option.HS_code) {
      temp[index].HS_code = option.HS_code
      temp[index].HSCodeByArticle = true
    }
    if (option.goodsName) {
      temp[index].goodsNameForBL = option.goodsName
      temp[index].goodsNameByArticle = true
    }
    if (option.brand_id) {
      temp[index].brand = option.brand_id
      temp[index].brandByArticle = true
    }
    if (option.productGroup) {
      const productGroupData = await this.getProductGroupData(
        option.productGroup
      )
      temp[index] = { ...temp[index], ...productGroupData }
    }
    this.productsList = [...temp]
  }

  selectRows = (selectedIdList) => (this.selectedRows = selectedIdList)

  editRow = (index) => {
    this.isEdit = `${index}`
    this.productsList[index].isEdit = true
    this.tmpRow = new Object(this.productsList[index])
  }

  getOneProduct = async (index) => {
    this.isLoading = true
    let id = this.productsList[index].id
    let response = await axios.get(`/api/node/v1/sku/getProduct/${id}`)
    this.productsList[index] = response.data.data
    const xyz = [...this.productsList]
    this.productsList = null
    this.productsList = xyz
    this.isLoading = false
  }

  pasteFromExcel = (value, idx, param) => {
    let initialIndex = this.pasteList.findIndex((el) => el === param)
    if (initialIndex === -1) {
      return
    }

    value.preventDefault()
    value = value.clipboardData.getData('text')
    value = value.split('\t')

    for (let [index, el] of value.entries())
      this.onChangeValue(el, idx, this.pasteList[index + initialIndex])
  }

  getFiltersData = async () => {
    let response = await axios.get('/api/node/v1/sku/getFilters')
    if (!response.data.success) return
    let data = response.data.data
    this.filtersParams['intCode1'].options = data.categoriesIntCodes
    this.filtersParams['intCode2'].options = data.categoriesIntCodes2
    this.filtersParams['subIntCode1'].options = data.subCategoriesIntCodes
    this.filtersParams['subIntCode2'].options = data.subCategoriesIntCodes2
    this.filtersParams['category1'].options = data.categories
    this.filtersParams['category2'].options = data.subCategories
    this.filtersParams['supplierCode'].options = data.factories
    this.filtersParams['brand'].options = data.brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }))

    this.filtersParams['article'].options = data.models

    this.factories = data.factories
    this.brands = data.brands
    return
  }

  deleteSKU = async (row, idx) => {
    if (!row.id) {
      this.productsList = [...this.productsList].slice(1)
      return
    }
    this.isLoading = true
    let response = await axios.post('/api/node/v1/sku/delete', {
      skuId: row.id,
    })
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Deleted!')
      this.productsList.splice(idx, 1)
    } else this.openNotificationWithIcon('error', response.data.message)
    this.isLoading = false
  }

  changeNewModelArticle = (value) => {
    this.newModelArticle = value
    this.lastNewModelArticle = value
    let temp = [...this.productsList]
    this.productsList = [...temp]
  }

  changeNewModel = (value) => {
    this.newModel = value
    this.checkOkButton()
  }

  clearNewModel = (e) => {
    if (e) return
    this.newModelArticle = ''
  }

  openModal = (index) => {
    this.addModelModalOpened = true
    this.newModelIndex = index
  }

  changeLastNewModelArticle = (value) => {
    this.lastNewModelArticle = value
    this.checkOkButton()
  }

  checkOkButton = () => {
    if (this.lastNewModelArticle === '' || this.newModel === '')
      this.saveModelButtonDisabled = true
    else this.saveModelButtonDisabled = false
  }

  closeModal = () => {
    this.addModelModalOpened = false
    this.lastNewModelArticle = ''
    this.newModelIndex = null
    this.newModel = ''
  }

  saveNewModel = async () => {
    this.saveModelButtonLoading = true
    let response = await axios.post('/api/node/v1/sku/addModel', {
      article: this.lastNewModelArticle,
      name: this.newModel,
    })
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Model saved')
      if (!_.isNil(this.newModelIndex)) {
        this.findModels(
          this.lastNewModelArticle,
          this.newModelIndex,
          undefined,
          true
        )
      }
      this.closeModal()
    } else this.openNotificationWithIcon('error', response.data.message)
    this.saveModelButtonLoading = false
    this.saveModelButtonDisabled = true
  }

  checkHSCode = async (index) => {
    if (!this.productsList[index].HS_code) return
    if (this.productsList[index].HS_code.toString().length < 10) {
      this.productsList[index].HS_code = null
      return
    }
    if (this.productsList[index].HS_code.toString().length > 10)
      this.productsList[index].HS_code = this.productsList[
        index
      ].HS_code.toString().slice(0, 10)
  }

  toggleColumnsSelector = (flag) => {
    this.isColumnsSelectorOpened = flag
  }

  changeSelectedColumns = (dataIndex) => {
    if (dataIndex === 'all') {
      if (this.selectedAllColumns) {
        this.selectedAllColumns = false
        let allColumns = this.columns.map((el) => el.dataIndex)
        this.selectedColumns = allColumns
      } else {
        this.selectedAllColumns = true
        this.selectedAllColumnsInterminate = false
        this.selectedColumns = []
      }
      this.saveUnselectedColumns()
      return
    }

    let idx = this.selectedColumns.findIndex((el) => el === dataIndex)
    if (idx !== -1) this.selectedColumns.splice(idx, 1)
    else this.selectedColumns.push(dataIndex)

    if (this.selectedColumns.length !== 0) {
      if (this.selectedColumns.length === this.columns.length) {
        this.selectedAllColumns = false
        this.selectedAllColumnsInterminate = false
      } else {
        this.selectedAllColumnsInterminate = true
        this.selectedAllColumns = false
      }
    } else {
      this.selectedAllColumnsInterminate = false
      this.selectedAllColumns = true
    }

    this.saveUnselectedColumns()

    //чтобы не плыла шапка таблицы при выборе отображаемых колонок
    let table = document.querySelector('.ant-table-body')
    if (table.scrollLeft > 1300) table.scrollLeft = 1300
  }

  saveUnselectedColumns = () => {
    axios.put('/api/node/v1/sku/updateColumns', {
      columns: JSON.stringify(this.selectedColumns),
    })
  }

  //экспорт в эксель
  export = async (deletePhotos = false) => {
    this.exportLoading = true
    const selectedRows = [...this.selectedRows]
    if (selectedRows.length !== 0 && selectedRows[0] === undefined)
      selectedRows.splice(0, 1)
    const response = await axios({
      url: '/api/node/v1/sku/export',
      responseType: 'blob',
      method: 'post',
      data: { selectedRows, deletePhotos },
    })
    if (response.status === 500) {
      this.openNotificationWithIcon('error', 'Error!')
    } else {
      const blob = await response.data
      download(blob, `SKU list ${moment().format('DD.MM.YYYY')}.xlsx`)
    }
    this.exportLoading = false
  }

  setTranslate = (t, i18n) => {
    this.t = t
    this.i18n = i18n
  }

  initColumnsFilter = async () => {
    const response = await axios.get('/api/node/v1/sku/columns')
    let columns = JSON.parse(response.data.data)
    if (!columns || columns.length === 0) return
    this.selectedColumns = columns

    if (this.selectedColumns.length !== 0) {
      this.selectedAllColumns = false
      if (this.selectedColumns.length !== this.columns.length)
        this.selectedAllColumnsInterminate = true
    }
  }

  openExportModal = async () => {
    this.exportModalOpen = true
  }

  closeExportModal = async () => {
    this.exportModalOpen = false
  }

  exportToExcel = async () => {
    await this.export(this.exportToExcelType !== 1)
    this.closeExportModal()
  }

  changeExportToExcelType = (value) => {
    this.exportToExcelType = value
  }

  resetSettings = () => {
    this.clearFiltersData()
    this.productsList = []
    this.queriesList = []
  }
}

export default SKUstore
