import { makeAutoObservable, toJS } from 'mobx'
import { notification } from 'antd'
import i18next from 'i18next'
import axios from 'axios'
import dayjs from 'dayjs';
const moment = require('moment')
const download = require('downloadjs')


class UsersStore {
  userAccess = false
  user = {}
  users = []
  userAvatar = ''
  loading = false
  saveUserModal = {
    loading: false,
    open: false,
    type: '',
    departmentsOpt: [],
    postsOpt: [],
    updatingUserRow: null
  }
  isExcelBtnLoading = false
  filtersParams = {
    departments: {
      filtered: [],
    },
    posts: {
      filtered: [],
    }
  }

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  getUsers = async () => {
    try {
      this.loading = true
      let params = {
        departments: this.filtersParams.departments.filtered,
        posts: this.filtersParams.posts.filtered
      }
      let response = await axios.get(`/api/node/v1/users`, {params})
      const data = response.data.data
      this.user = data.user
      this.userAccess =
      this.user.dep_id === 5 || data.user.dep_id === 6 || data.user.post_id === 35  ? true : false
      this.users = response.data.data.rows
      let lang = i18next?.language
      lang = lang === 'ru' ? 'rus' : lang
      const depTranslateValue = `name_${lang}`
      this.saveUserModal.departmentsOpt = data.departments.map(
        dep => ({
          value: dep.id,
          label: dep[depTranslateValue]
        })
      )
      this.saveUserModal.postsOpt = data.posts.map(
        post => ({
          value: post.id,
          label: post.name
        })
      )

    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.loading = false
    }
  }


  deleteRow = async (id) => {
    try {
      this.loading = true
      await axios.delete(`/api/node/v1/users/${id}`)
      await this.getUsers()
      this.openNotificationWithIcon('success', i18next.t('w_sucess'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      this.loading = false
    }
  }

  acceptFilter = async (type, data) => {
    this.filtersParams[type].filtered = data
    await this.getUsers()
  }

  onClearFilter= async (type)=>{
    this.filtersParams[type].filtered = []
    await this.getUsers()
 }

 clearAllFilters = async (withGetusers = true) => {
  for (let filter in this.filtersParams) {
      this.filtersParams[filter].filtered=[]
  }
  if(withGetusers) {
    await this.getUsers()  
  }
}

  resetPassword = async (row) => {
    try {
      const body = {
        name: row.name,
        surname: row.surname,
        email: row.email,
      }
      this.state = 'reseting_passwotd'
      await axios.put(`/api/node/v1/users/password/${row.id}`, { body })
      this.openNotificationWithIcon(
        'success',
        'Email was sent with new password to user!'
      )
    } catch (error) {
      this.openNotificationWithIcon('error', 'Error!')
      console.error(error)
    }
  }


  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }


  getUsersExcel = async () => {
    try{
      this.isExcelBtnLoading = true
      const params = {
        departments: this.filtersParams.departments.filtered,
        posts: this.filtersParams.posts.filtered,
        language:i18next.language
      }
  
      const response = await axios({
        url: '/api/node/v1/users/excel',
        responseType: 'blob',
        method: 'get',
        params
      })
      const blob = await response.data
      download(blob, `Users ${moment().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }

  // SaveUserModal
  addUser = async (values, avatar) => {
    try {
      this.saveUserModal.loading = true
      let response = await axios.post(`/api/node/v1/users`, values)
      if(typeof avatar === 'object' && avatar !== null) {
        let id = response.data.data
        await this.updateAvatar(avatar, id)
      } else {
        this.openNotificationWithIcon('success', i18next.t('w_succeess'))
      }
      await this.getUsers()
      this.saveUserModal.open = false
    } catch (error) {
      if (error.response?.data?.errors === 'w_this_email_already_exists') {
        this.openNotificationWithIcon('error', i18next.t('w_this_email_already_exists'))
      } else {
        this.openNotificationWithIcon('error', i18next.t('w_error'))
        console.error(error)
      }
    } finally {
      this.saveUserModal.loading = false
    }
  }

  updateUser = async (values, avatar) => {
    try {
      this.saveUserModal.loading = true
      const id =  this.saveUserModal.updatingUserRow.id
      await axios.put(`/api/node/v1/users/${id}`, values)
      if(typeof avatar === 'object' && avatar !== null) {
        await this.updateAvatar(avatar, id)
        if(this.user.user_id === id) {
          await this.getUserAvatar()
        }
      } else {
        this.openNotificationWithIcon('success', i18next.t('w_succeess'))
      }
      await this.getUsers()
      this.saveUserModal.open = false
    } catch (error) {
      if (error.response?.data?.errors === 'w_this_email_already_exists') {
        this.openNotificationWithIcon('error', i18next.t('w_this_email_already_exists'))
      } else {
        this.openNotificationWithIcon('error', i18next.t('w_error'))
        console.error(error)
      }
    } finally {
      this.saveUserModal.loading = false
    }
  }

  getUserAvatar = async () => {
    try{
      let response = await axios.get('/api/node/v1/users/getAvatar')
      this.userAvatar = response.data.data.avatar
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  };

  updateAvatar = async (avatar, id) => {
    try {
      const formData = new FormData()
      formData.append(`avatar`, avatar.file)
      await axios.put(`/api/node/v1/users/avatar/${id}`, formData)
      this.openNotificationWithIcon('success', i18next.t('w_succeess'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    } 
  }

  onFinishSave = async (values) => {
   
    let { avatar, ...otherValues } = values;
    avatar = avatar?.file?.type.startsWith('image/') ? avatar : null
    const transformedValues = {
      ...otherValues,
      birthday_date: this.convertToDate(values.birthday_date),
      start_working_date: this.convertToDate(values.start_working_date),
      finish_working_date: this.convertToDate(values.finish_working_date),
    };
    if(this.saveUserModal.type === 'add') {
      await this.addUser(transformedValues, avatar)
    }
    else {
      await this.updateUser(transformedValues, avatar)
    }

  }
  
  changeSaveUserModal = (key, value) => {
    this.saveUserModal[key] = value
  }

  openSaveUserModal = (type, row = null) => {
    this.saveUserModal.open = true
    this.saveUserModal.type = type
    if(type === 'update'){
      this.saveUserModal.updatingUserRow = {
        ...row,
      birthday_date: row.birthday_date ? dayjs(row.birthday_date) : null,
      start_working_date: row.start_working_date ? dayjs(row.start_working_date) : null,
      finish_working_date: row.finish_working_date ? dayjs(row.finish_working_date) : null,
      }
    }
  }

  convertToDate = (date) => {
    return date ? date.toDate() : null; 
  };






}

export default UsersStore
